body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-group i {
  position: absolute;
  right: 15px;
  top: 8px;
  font-size: 18px;
  color: #3b4249;
  z-index: 1;
}

.date-selection.register-date-selection {
  padding: 10px;
}

.btn-full-width svg {
  left: 12px;
  top: 7px;
  position: absolute;
}

.form-group input {
  padding: 10px 30px 11px 12px;
}

.rdrDefinedRangesWrapper{
  display: none;
}

.lp-slide {
  padding: 0 15px 20px 25px;
  /* padding: 0px; */
}

.pr-slide {
  padding: 0 3px;
}

.listing-price-tag {
  background: #ffffff;
  border-radius: 50px;
  color: #000;
  cursor: pointer;
  height: 2.5em;
  position: relative;
  width: 5.5em;
  border: 1px solid transparent;
  box-shadow: rgb(0 0 0 / 8%) 0px 0px 0px 1px, rgb(0 0 0 / 18%) 0px 1px 2px;
  font-weight: 600;
  font-size: 13px;
}

.listing-price-tag-text {
  text-align: center;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.dropzone-container {
  border: 2px dashed rgb(187, 187, 187) !important;
  border-radius: 6px !important;
  height: auto;
  cursor: pointer !important;
  position: relative !important;
  margin: 0px !important;
  padding: 20px !important;
  width: 100% !important;
  display: table !important;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid rgb(234, 234, 234);
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}

.thumb-container {
  display: flex;
  flex-flow: row wrap;
  margin-top: 16px;
}

.thumb-inner {
    display: flex;
    min-width: 0;
    overflow: hidden;
}

.img-preview {
    display: block;
    width: auto;
    height: 100%;
}

.preview-wrapper {
      border-collapse: separate;
    caption-side: top;
    caret-color: auto;
    color: inherit;
    cursor: auto;
    empty-cells: show;
    font-feature-settings: normal;
    font-kerning: auto;
    hyphens: none;
    image-rendering: auto;
    letter-spacing: normal;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: disc;
    object-position: 50% 50%;
    orphans: 2;
    pointer-events: auto;
    quotes: initial;
    ruby-position: over;
    tab-size: 8;
    text-align: initial;
    text-align-last: auto;
    text-combine-upright: none;
    text-indent: 0px;
    text-orientation: mixed;
    text-rendering: auto;
    text-shadow: none;
    text-transform: none;
    text-underline-position: auto;
    visibility: visible;
    white-space: normal;
    widows: 2;
    word-break: normal;
    word-spacing: normal;
    overflow-wrap: normal;
    writing-mode: horizontal-tb;
    appearance: none;
    backface-visibility: visible;
    bottom: auto;
    box-shadow: none;
    clear: none;
    clip: auto;
    column-count: auto;
    column-fill: balance;
    column-gap: normal;
    column-width: auto;
    content: normal;
    counter-increment: none;
    counter-reset: none;
    float: none;
    height: auto;
    left: auto;
    max-height: none;
    max-width: none;
    min-height: 0px;
    min-width: 0px;
    opacity: 1;
    overflow-x: visible;
    overflow-y: visible;
    perspective: none;
    perspective-origin: 50% 50%;
    position: static;
    right: auto;
    table-layout: auto;
    top: auto;
    transform: none;
    transform-origin: 50% 50% 0px;
    transform-style: flat;
    unicode-bidi: normal;
    vertical-align: baseline;
    width: auto;
    z-index: auto;
    box-sizing: border-box;
    border-spacing: 0px;
    animation: 0s ease 0s 1 normal none running none;
    background: none 0px 0px / auto repeat scroll padding-box border-box transparent;
    border-width: medium;
    border-style: none;
    border-color: currentcolor;
    border-image: none 100% / 1 / 0 stretch;
    border-radius: 0px;
    column-rule: medium none currentcolor;
    margin: 0px;
    padding: 0px;
    page-break-after: auto;
    page-break-before: auto;
    page-break-inside: auto;
    text-decoration: none;
    transition: none 0s ease 0s;
    font: inherit;
}

.inline-otp input {
  display: block;
  width: 3em !important;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.inline-otp span {
  padding: .375rem .75rem;
  font-size: 14px;
}

.react-tel-input .form-control {
  width:100% !important;
}

.common-input label {
  white-space: nowrap;
  margin-top: 8px;
}

.student-discount i {
  position: relative;
  right: 0px;
  /* margin-bottom: 11px; */
  top: 5px;
  left: 10px;
}

.p-inputswitch .p-inputswitch-slider:before {
  width: 13px !important;
  height: 12px !important;
  /* left: -4px !important; */
  margin-top: -6px !important;
}

.p-inputswitch {
  width: 40px !important;
  height: 20px !important;
}

.alert-info {
  background-color: #E3F0F6;
  border-color: #1da1f2;
  color: #1da1f2;
}

.read-more i {
  position: relative;
  right: 0px;
  left: 3px;
  top: 1px;
  font-size: small;
}

.flag-tab svg{
  width : 30px;
  cursor: pointer;
}

.flag-dropdown svg{
  width : 20px;
  margin-right: 5px;
  cursor: pointer;
}

.noti-badge {
  top: 0 !important;
  right: 12px !important;
}

.reserve-warning{
  border-top: 0px !important;
  padding: 0px !important;
}

.amenities-list span {
  padding-left: 5px;
}

.amenities-list p svg {
  height: auto;
}

.wnd-text small {
  text-transform: capitalize;
}

div.wn-dropdown-menu.dropdown-menu.show {
  background: transparent !important;
  position: absolute;
  top: -68px !important;
  left: 62px !important;
  z-index: 1000;
  float: left;
  min-width: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: unset !important;
  border: 0px !important;
  border-radius: .25rem;
}

.country-change-continue{
  background: #19CC66 !important;
    color: #fff;
    border: none;
}

.manage-cancel-buttons .btn {
  font-size: 14px;
}

.custom-modal-login .modal-body-login {
  padding: 10px 30px 13px;
}

.review-btn i {
  position: relative;
  bottom: 2px;
}

.booking-list .listing-media {
  width: 200px;
  height: 122px;
}

@media (prefers-reduced-motion: reduce){
  .inline-otp input {
      transition: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

