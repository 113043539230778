* { margin: 0; padding: 0; }
h1,h2,h3,h4,h5,h6,p,a { margin: 0; padding: 0; } 
h1,h2,h3,h4,h5,h6 { font-weight: 700; }
ul , li , ol { list-style: none; margin: 0; padding: 0; }
img { max-width: 100%; border: none; }
a , a:hover , a:focus , a:active , button , button:hover, button:focus , button:active , input , input:hover , input:focus , input:active , select , select:hover , select:focus {
	text-decoration: none;
	outline: none;
}

/* common styles start */

/* loader start */

.loader-outer {
    display: block;
}

.loader-inner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

body .loader-logo img.logo-color {
    display: block;
    width: 220px;
    margin-bottom: 20px;
}

.common {
    display: inline-block;
    position: relative;
}
.spinner{
    width:28px;
    height:28px;
    display:inline-block;
    position:relative;
    text-align:initial;
    color:#4285f4;
}
.spinnerContainer {
    width: 100%;
    height: 100%;
    direction: ltr;
}
.spinnerContainer.active {
    animation: container-rotate 1568ms linear infinite;
}
@keyframes container-rotate {
    to {
        transform: rotate(360deg)
    }
}
.spinner-layer {
    position: absolute;
    width: 100%;
    height: 100%;
}
.active .spinner-layer.layer-1 {
    animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
}


@keyframes fill-unfill-rotate {
    12.5% {
        transform: rotate(135deg);
    }
    25% {
        transform: rotate(270deg);
    }
    37.5% {
        transform: rotate(405deg);
    }
    50% {
        transform: rotate(540deg);
    }
    62.5% {
        transform: rotate(675deg);
    }
    75% {
        transform: rotate(810deg);
    }
    87.5% {
        transform: rotate(945deg);
    }
    to {
        transform: rotate(1080deg);
    }
}


.gap-patch {
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 45%;
    width: 10%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;
}
.gap-patch .circle {
    width: 1000%;
    left: -450%;
}
.circle-clipper {
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;
}
.circle-clipper .circle {
    width: 200%;
}
.circle {
    box-sizing: border-box;
    height: 100%;
    border-style: solid;
    border-color: inherit;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    animation: none;
    box-shadow: none;
    color: #1da1f2;
}
.circle-clipper.left .circle {
    border-right-color: transparent !important;
    transform: rotate(129deg);
}
.circle-clipper.right .circle {
    left: -100%;
    border-left-color: transparent !important;
    transform: rotate(-129deg);
}
.active .circle-clipper.left .circle {
    animation: left-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
}
.active .circle-clipper.right .circle {
    animation: right-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
}

@keyframes left-spin {
    from {
        transform: rotate(130deg);
    }
    50% {
        transform: rotate(-5deg);
    }
    to {
        transform: rotate(130deg);
    }
}

@keyframes right-spin {
    from {
        transform: rotate(-130deg);
    }
    50% {
        transform: rotate(5deg);
    }
    to {
        transform: rotate(-130deg);
    }
}

/* loader ends */

body {
	font-family: 'Poppins', sans-serif;
	font-size: 13px;
	color: #000;
	font-weight: 400;
	padding-top: 109px;
}

body.homepage {
	padding-top: 0;
}

header {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    border-bottom: 1px solid #dfdfdf;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    background: #fff;
}

body.homepage header {
	border-color: transparent;
    background: transparent;
}

body.homepage.sticky-header img.logo-color {
    display: block;
}

body.homepage.sticky-header img.logo-white {
    display: none;
}

body.homepage.sticky-header header {
    background: #fff;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
}

body.homepage.sticky-header .navigation ul li a {
    color: #222;
}

.homepage.sticky-header .navigation ul li.support a svg {
    fill: #222;
}

body.homepage.sticky-header .navigation ul li.host-btn a {
    color: #fff;
}

img.logo-white {
    display: none;
}

body.homepage img.logo-white {
    display: block;
}

/* homepage start */

.header-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.logo {
    width: 200px;
    height: 100%;
    padding: 10px 0 10px 0;
}

.navigation {
    width: auto;
}

.navigation ul {
    width: auto;
    display: flex;
}

.navigation ul li {
    width: auto;
    display: flex;
    align-items: center;
    position: relative;
}

.navigation ul li a {
    font-size: 14px;
    color: #222;
    font-weight: 600;
    padding: 10px 20px;
    display: flex;
    position: relative;
}

body.homepage .navigation ul li a {
	color: #fff;
}

.navigation ul li.host-btn {
    margin-left: 15px;
}

.navigation ul li.host-btn a {
    background: #19CC66;
    padding: 9px 20px;
    border-radius: 4px;
    color: #fff;
}

.navigation ul li.support a {
    padding: 33.5px 20px;
}

.navigation ul li.support a svg {
    width: 25px;
    fill: #1da1f2;
}

section.hero-section {
    width: 100%;
    height: 100vh;
}

section.hero-section:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.5);
    z-index: 1;
}

section.hero-section {
    width: 100%;
    height: 100vh;
    background: url(../img/hero-bg.jpg) no-repeat scroll center center / cover;
    position: relative;
}

section.hero-section .container {
	height: 100%;
}

.hero-section-inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.hero-caption {
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 9;
}

.hero-caption h1 {
    font-size: 38px;
    color: #fff;
    margin-bottom: 20px;
}

.hero-caption p {
    font-size: 22px;
    font-weight: 500;
    color: #fff;
}

.main-search {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.search-place {
    width: 330px;
    margin-right: 10px;
}

.ms-input {
    width: 100%;
    position: relative;
}

.ms-input i {
    position: absolute;
    left: 12px;
    top: 16px;
    font-size: 18px;
    color: #3b4249;
    z-index: 1;
}

.ms-input input , .ms-input select {
    width: 100%;
    background: #fff;
    border: none;
    border-radius: 6px;
    padding: 15px 15px 15px 40px;
    font-size: 15px;
}

.ms-input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #3b4249;
}

.ms-input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #3b4249;
    opacity: 1;
}

.ms-input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #3b4249;
    opacity: 1;
}

.ms-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #3b4249;
}

.ms-date {
    width: 180px;
    margin-right: 10px;
}

.guest {
	width: 180px;
}

.ms-search-btn {
    width: auto;
    margin-left: 10px;
}

.ms-search-btn button {
    width: auto;
    border: none;
    background: #1DA1F2;
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
    padding: 14px 40px;
    font-size: 16px;
}

.ms-search-btn button i {
    margin-right: 10px;
}

section.section{
    width: 100%;
    padding: 70px 0;
}

.common-heading {
    width: 100%;
    padding-bottom: 30px;
}

.common-heading h2 {
    font-size: 24px;
    line-height: 30px;
    color: #222;
    margin-bottom: 6px;
}

.common-heading p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #3b4249;
}

.featured-pod-box {
    width: 100%;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 5px 5px 10px 0 rgb(0 0 0 / 10%);
    -webkit-box-shadow: 5px 5px 10px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 5px 5px 10px 0 rgb(0 0 0 / 10%);
    background: #fff;
}

.fpb-image {
    width: 100%;
    position: relative;
    height: 225px;
    overflow: hidden;
}

.fpb-image a {
    width: 100%;
    position: relative;
    display: inline-block;
    height: 100%;
    transition: all 1s linear;
    -webkit-transition: all 1s linear;
    -moz-transition: all 1s linear;
}

.fpb-image a img {
    max-width: 100%;
    height: auto;    
    transition: all 1s linear;
    -webkit-transition: all 1s linear;
    -moz-transition: all 1s linear;
}

.fpb-image a:hover img {
	transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	transition: all 1s linear;
    -webkit-transition: all 1s linear;
    -moz-transition: all 1s linear;
}

.fpb-image-caption {
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px 20px 20px;
    align-items: flex-end;
}

.fpb-price {
    font-size: 28px;
    font-weight: 500;
    text-transform: none;
    letter-spacing: normal;
    color: #fff;
    line-height: 25px;
    position: relative;
}

.fpb-price sup, .fpb-price sub {
    font-size: 20px;
    font-weight: 500;
}

.block-icon img {
    width: 70px;
}

.stm-outer {
    width: 100%;
    padding-top: 20px;
    color: #3b4249;
}

.stm-outer h2 {
    padding-left: 40px;
    margin-bottom: 20px;
}

.standard-amenities .block.block-bordered {
    border-top: 1px dotted #d8dce1;
}

.student-discount-text {
    position: absolute;
    top: 10px;
    left: 10px;
    background: rgba(0,0,0,0.7);
    z-index: 1;
    font-size: 10px;
    color: #fff;
    padding: 3px 6px;
    border-radius: 6px;
}

.form-control {
    font-size: 14px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.gr-overlay {
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.fpb-avatar {
    width: 36px;
    height: 36px;
}

.fpb-avatar img {
    width: 100%;
    border-radius: 100%;
    height: 100%;
    border: 2px solid #fff;
}

.fpb-price sub {
    bottom: 2px;
}

.fpb-price sup {
    top: -5px;
}

.fpb-badge {
    position: absolute;
    top: 12px;
    left: -39px;
    background: #19cc66;
    color: #fff;
    z-index: 1;
    font-weight: 400;
    font-size: 12px;
    padding: 3px 40px;
    transform: rotate(-36deg);
    -webkit-transform: rotate(-36deg);
    -moz-transform: rotate(-36deg);
} 

.fpb-content a {
    font-size: 16px;
    color: #222;
    font-weight: 700;
    margin-bottom: 2px;
    display: inline-block;
}

.fpb-content {
	width: 100%;
	padding: 20px;
}

.fpb-content p {
    font-size: 14px;
    color: #777;
    font-weight: 500;
}

.fpb-amenities {
    margin-top: 8px;
    width: 100%;
}

.fpb-amenities ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.fpb-amenities ul li {
    margin-right: 10px;
    font-size: 12px;
    color: #777;
    font-weight: 500;
    position: relative;
}

.fpb-amenities ul li:after {
    content: "";
    width: 3px;
    height: 3px;
    position: absolute;
    right: -6px;
    top: 50%;
    border-radius: 100%;
    background: #777;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}

.fpb-amenities ul li:last-child:after {
    display: none;
}

.fpb-slots {
    width: 100%;
    display: flex;
    margin-top: 5px;
    align-items: flex-start;
    flex-direction: column;
}

.fpb-slots p {
    color: #222;
    margin-right: 15px;
    width: auto;
    flex: 0 0 auto;
    font-size: 13px;
    margin-bottom: 6px;
}

.slots-inner {
    width: 100%;
}

.slot-text {
    font-size: 12px;
    color: #333;
    border: 1px solid #d6d6d6;
    border-radius: 50px;
    margin-right: 6px;
    padding: 2px 5px;
}

.listing-price {
    width: 100%;
    text-align: right;
}

.listing-price {
    width: 100%;
    text-align: right;
}

.fpb-price small {
    font-size: 18px;
    color: #333;
}

.fpb-amenities ul li i {
    margin-right: 0px;
    font-size: 14px;
    color: #525252;
}

.fpb-star {
    margin-top: 15px;
}

.fpb-star i {
    color: #f8b42b;
}

.fpb-star span {
    color: #777;
}

.bg-light-gray {
	background: #F7F8F9;
}

.tp-box {
    width: 100%;
    height: 240px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 30px;
}

.tp-box img {
    max-width: initial;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
}

.tp-box-caption {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    z-index: 1;
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 20px;
}

.section.trending-pods {
	padding-bottom: 40px;
}

.section.modern-pods {
	padding: 100px 0;
	background: url(../img/modern-pod-bg.jpg) no-repeat scroll center center / cover;
}

.modern-pod-box {
    width: 100%;
    position: relative;
    padding: 50px 70px;
    background: rgba(255,255,255,0.9);
    overflow: hidden;
}

.modern-pod-box h2 {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 15px;
    color: #222;
}

.modern-pod-box span {
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
    margin-bottom: 15px;
    display: inline-block;
    width: 100%;
}

.modern-pod-box p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #222;
    margin-bottom: 25px;
    width: 100%;
    display: inline-block;
}

.btn-big {
    border: none;
    background: #1DA1F2;
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
    padding: 10px 20px;
    font-size: 16px;
    width: auto;
    display: inline-block;
}

.btn-big:hover {
	color: #fff;
}

.mp-badge {
    position: absolute;
    background: #19cc66;
    color: #fff;
    width: auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    padding: 3px 40px;
    left: -37px;
    top: 18px;
    transform: rotate(-40deg);
    -webkit-transform: rotate(-40deg);
    -moz-transform: rotate(-40deg);
}

.tp-box.comfortpod-box {
    height: 248px;
}

.align-center {
    text-align: center;
}

.section.comfort-pod {
	padding-bottom: 40px;
}

.more-btn {
    width: 100%;
    text-align: center;
    padding: 30px 0;
}

.host-inner {
    width: 100%;
    margin-top: 20px;
    display: inline-block;
}

.hosts-box {
    width: 100%;
}

.host-message {
    background-color: #fff;
    border: 1px solid #d8dce1;
    padding: 10px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 20px;
    min-height: 220px;
}

.host-message:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #fff;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
}

.host-message:before {
	content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #d8dce1;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -11px;
}

.host-message p {
    font-size: 15px;
    text-align: center;
    width: 100%;
}

.host-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.host-info img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
}

.host-info h4 {
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
}

.host-info i {
    font-size: 15px;
    margin-top: 5px;
    color: #333;
}

.blog-inner {
    width: 100%;
    margin-top: 20px;
    display: inline-block;
}

.blog-box {
    width: 100%;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 10%);
    -webkit-box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 10%);
    cursor: pointer;
}

.blog-img {
    width: 100%;
    height: 233px;
    position: relative;
    overflow: hidden;
}

.blog-img a {
    width: 100%;
    display: inline-block;
}

.blog-img a img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    height: 100%;
    width: auto;
    max-width: none;
}

.blog-content {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.blog-title {
    width: 100%;
    font-size: 16px;
    color: #222;
    font-weight: 600;
    margin-bottom: 10px;
}

.blog-title:hover {
	color: #222;
}

.blog-category {
    width: auto;
    font-size: 16px;
    color: #19cc66;
    margin-bottom: 15px;
}

.blog-category:hover {
	color: #19cc66;
}

.blog-category i {
    font-size: 14px;
    color: #5d5d5d;
    margin-right: 3px;
}

.blog-content p {
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;
}

.blog-author {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.blog-author span {
    width: auto;
    margin-right: 14px;
    color: #333;
    font-size: 14px;
}

.blog-author span a {
    color: #19cc66;
}

footer {
    width: 100%;
}

.footer-inner {
    width: 100%;
    padding: 100px 0;
}

.f-info {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.f-info p {
    font-size: 15px;
    color: #222;
}

.f-readmore-link {
    margin-top: 30px;
    color: #000;
    font-weight: 600;
    font-size: 16px;
}

.f-logo {
    max-width: 200px;
    margin-bottom: 20px;
}

.footer-heading {
    font-size: 18px;
    color: #222;
    margin-bottom: 30px;
}

.f-nav {
    width: 100%;
}

.f-nav ul {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.f-nav ul li {
    margin-bottom: 12px;
}

.f-nav ul li a {
    font-size: 14px;
    color: #222;
    font-weight: 600;
    position: relative;
    padding-left: 10px;
}

.f-nav ul li a:after {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    position: absolute;
    left: 0;
    top: 3px;
    font-size: 9px;
}

.f-contact {
    width: 100%;
}

.f-contact ul {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.f-contact ul li {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #dfdfdf;
}

.f-contact ul li a {
    font-size: 14px;
    color: #222;
    position: relative;
    padding-left: 25px;
    font-weight: 500;
}

.f-contact ul li a i {
    position: absolute;
    left: 0;
    top: 2px;
}

.copyright {
    width: 100%;
    padding-bottom: 10px;
}

.copyright-text {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.copyright-text p {
    font-size: 14px;
    color: #222;
}

.f-social {
    width: 100%;
}

.f-social ul {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.f-social ul li {
    width: auto;
}

.f-social ul li a {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #222;
    position: relative;
    overflow: hidden;
    border-radius: 100%;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
}

.f-social ul li a:hover {
	color: #fff;
	transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
}

.f-social ul li a:hover:before {
	width: 100%;
	height: 100%;
	left: 50%;
	top: 50%;
	transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    border-radius: 100%;
}

.f-social ul li a.fb:hover:before {
	background: #506dab;
}

.f-social ul li a.twt:hover:before {
	background: #00aced;
}

.f-social ul li a.linkd:hover:before {
	background: #007bb6;
}

.f-social ul li a.insta:hover:before {
	background: #517fa4;
}

.f-social ul li a:before {
    content: "";
    width: 0%;
    height: 0%;
    background: #613e3e;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    border-radius: 0%;
}

.f-social ul li a i {
    z-index: 1;
}

.custom-modal-login .modal-dialog {
    max-width: 720px;
    display: flex;
    width: 100%;
}

/* .custom-modal-login .modal-dialog {
    -webkit-box-shadow: 0px 0px 20px rgb(0 0 0 / 20%);
    box-shadow: 0px 0px 20px rgb(0 0 0 / 20%);
} */

.custom-modal-login .modal-body-left, .custom-modal-login .modal-body-right {
    width: 50%;
    position: relative;
}

.custom-modal-login .modal-body-left:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
}

.login-register-title {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 32px;
    line-height: 40px;
    font-weight: 300;
    padding: 0 30px;
    color: #fff;
    text-align: center;
}

.custom-modal-login .modal-content {
    border-radius: 0;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.custom-modal-login .modal-header {
    padding: 25px 30px 0;
    border-bottom: none;
}

.custom-modal-login .modal-body {
    padding: 30px 30px 13px;
}

.btn-facebook-lined {
    color: #506dab;
    border: 1px solid #506dab;
    background-color: transparent;
}
.btn-full-width {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
}

.btn-facebook-lined i {
    left: 15px;
    top: 9px;
    position: absolute;
}

.btn-google-plus-lined {
    color: #dd4b39;
    border: 1px solid #dd4b39;
    background-color: transparent;
}

.modal-login-form p {
    margin: 10px 0 20px;
}

.modal-login-form .form-group {
    margin-bottom: 0;
}

.modal-login-form .forgot-password-text, .modal-login-form .checkbox {
    margin: 15px 0;
}

.modal-title {
    font-size: 18px;
    color: #222;
}

.btn-full-width {
    width: 100%;
    position: relative;
}

.btn-google-plus-lined i {
    left: 15px;
    top: 11px;
    position: absolute;
}

.modal-login-form .form-control.email-input-1 {
    border-radius: 4px 4px 0 0;
    border-bottom: none;
}

.modal-login-form .form-control.password-input-2{
    border-radius: 0 0 4px 4px;
}

.login-opt {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login-opt label {
    margin: 0;
    display: flex;
    align-items: center;
}

.login-opt label input {
    margin-right: 6px;
}

.custom-modal-login-inner {
    width: 100%;
    display: flex;
}

.btn-facebook-lined:hover, .btn-facebook-lined:focus, .btn-facebook-lined:active {
    color: #fff;
    background-color: #506dab;
}

.btn-google-plus-lined:hover, .btn-google-plus-lined:focus, .btn-google-plus-lined:active {
    color: #fff;
    background-color: #dd4b39;
}


/* homepage ends */

/* inner page starts */

.page-title {
    width: 100%;
    padding: 30px 0 0 0;
}

.page-title .common-heading {
    border-bottom: 1px solid #dfdfdf;
}

.page-title .common-heading h2 {
	margin-bottom: 0;
}

.main-listing-outer {
    padding-bottom: 40px;
}

.listing-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    align-items: center;
}

.listing-header p {
    font-size: 16px;
    color: #222;
}

.listing-sort {
    width: auto;
}

.listing-sort label {
    font-size: 16px;
    color: #222;
    font-weight: 600;
    white-space: nowrap;
    margin-right: 10px;
    margin-bottom: 0;
}

.listing-main {
    width: 100%;
}

.listing-main-wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    background: #fff;
    box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 10%);
    -webkit-box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 10%);
    margin-bottom: 15px;
}

.listing-main-wrap:last-child {
    margin-bottom: 0;
}

.listing-media {
    width: 300px;
    height: 200px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 0 0 auto;
}

.listing-media a {
    width: 100%;
    height: 100%;
    display: inline-block;
}

.listing-media a img {
    height: auto;
}

.listin-media-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 0 15px 15px 15px;
    width: 100%;
}

.listing-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    position: relative;
}

.swiper-slide .listing-body {
    height: 100%;
    text-align: left;
}

.listing-item-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 40px;
}

.listing-item-title a {
    font-size: 16px;
    color: #222;
    font-weight: 600;
    margin-bottom: 3px;
}

.listing-item-address {
    font-size: 14px;
    color: #777;
    font-weight: 500;
}

.listing-item-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    align-items: center;
}

.listing-item-host {
    width: auto;
    display: flex;
    align-items: center;
}

.listing-item-host img {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    flex: 0 0 auto;
    margin-right: 8px;
}

.listing-item-host span {
    font-size: 12px;
    font-weight: 600;
    color: #222;
    line-height: 14px;
}

.listing-item-rating {
    width: auto;
}

.listing-item-rating .fpb-star {
    margin: 0;
}

.sidebar-box {
    width: 100%;
    background: #fff;
    padding: 30px;
    margin-top: 30px;
    border-radius: 6px;
    margin-bottom: 30px;
}

.newsletter h3 {
    font-size: 18px;
    color: #222;
    margin-bottom: 20px;
}

.newsletter p {
    font-size: 15px;
    line-height: 25px;
    color: #222;
}

.news-letter-form {
    width: 100%;
    margin-top: 20px;
}

.btn-fill {
    width: auto;
    color: #fff;
    background: #1da1f2;
    border: none;
    border-radius: 4px;
    padding: 9px 15px;
    font-weight: 600;
}

.news-letter-form .btn-fill {
    margin-top: 10px;
}

.btn-fill.wd-full {
    width: 100%;
    text-align: center;
}

.sidebar-heading {
    font-size: 18px;
    color: #222;
    margin-bottom: 20px;
}

.sidebar-f-item {
    width: 100%;
    display: flex;
    height: 66px;
    position: relative;
    overflow: hidden;
    margin-bottom: 25px;
}

.sidebar-f-item:last-child {
	margin-bottom: 0;
}

.sf-item-media {
    width: 100px;
    flex: 0 0 auto;
    height: 66px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.sf-item-media a {
    width: 100%;
    display: inline-block;
}

.sf-item-media img {
    height: auto;
}

.sf-item-details {
    width: 100%;
    padding-left: 20px;
}

.sf-item-details a {
    font-size: 14px;
    color: #222;
    font-weight: 600;
}

.item-rarity {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 3px;
}

.item-rarity p {
    font-size: 14px;
    color: #222;
    font-weight: 500;
    margin-right: 15px;
}

.item-rarity .fpb-star {
    margin-top: 0;
}

.sf-item-details .fpb-amenities {
    margin-top: 2px;
}

.sf-item-details .fpb-amenities ul li {
    font-size: 13px;
    margin-right: 11px;
}

.sf-item-details .fpb-amenities ul li i {
    font-size: 12px;
}

.sidebar-explore {
    width: 100%;
    padding: 30px;
    border-radius: 6px;
    margin-bottom: 30px;
}

.sidebar-explore-box {
    width: 100%;
    height: 100px;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 10px;
}

.sidebar-explore-box:last-child {
	margin-bottom: 0;
}

.sidebar-explore-box a {
    width: 100%;
    display: inline-block;
}

.sidebar-explore-box a img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.sidebar-explore-box a span {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    z-index: 1;
    text-align: center;
    font-weight: 600;
    padding: 0 10px 10px 10px;
}

.sidebar-reviews {
    width: 100%;
}

.sidebar-reviews-wrap {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}

.sidebar-reviews-wrap:last-child {
	margin-bottom: 0;
}

.sr-img {
    width: 60px;
    height: 60px;
    flex: 0 0 auto;
}

.sr-img img {
    height: 100%;
    width: 100%;
    border-radius: 100%;
}

.sr-info {
    width: 100%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
}

.sr-info h3 {
    font-size: 14px;
    color: #222;
    margin-bottom: 10px;
}

.sr-info .fpb-star {
    margin-top: 5px;
}

.sr-info p {
    margin-top: 10px;
}

section.innerpage-filter {
    width: 100%;
    padding: 10px 0;
    position: relative;
}

.innerpage-filter-wrap .ms-input input, .innerpage-filter-wrap .ms-input select {
    border: 1px solid #ced4da;
    padding: 12px 15px 11px 40px;
    font-size: 13px;
}

.innerpage-filter-wrap .ms-input select {
    padding: 12px 15px 11px 15px;
}

.innerpage-filter-wrap .ms-input i {
    top: 12px;
}

.more-filter-toggle a {
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
    color: #3b4249;
    padding: 9px 13px;
    display: inline-block;
    width: 44px;
    height: 44px;
}

.innerpage-filter-wrap {
    display: flex;
    justify-content: center;
}

.place {
    width: 380px;
    margin-right: 10px;
}

.place-info {
    width: 176px;
    margin-right: 10px;
}

.place-info {
    width: 176px;
    margin-right: 10px;
}

.place-info {
    width: 175px;
    margin-right: 10px;
}

.more-filter-toggle {
    width: 44px;
    margin-right: 10px;
}

.filter-search {
    width: 163px;
}

.filter-search button.btn-fill {
    padding: 12.5px 15px;
}

.item-badge {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: auto;
    padding: 10px 10px 0 10px;
    z-index: 9;
}

.fpb-image .badge-it {
	margin-right: 5px;
}

.badge-it {
    width: 40px;
    height: 40px;
    background: #19cc66;
    border-radius: 100%;
    padding: 9px;
    position: relative;
    z-index: 9;
    cursor: pointer;
    display: inline-block;
}

.badge-tooltip {
    position: absolute;
    font-style: normal;
    color: #fff;
    background: #000;
    top: 42px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    font-size: 11px;
    visibility: hidden;
    opacity: 0;
    padding: 3px 6px;
    border-radius: 4px;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    text-align: center;
}

.badge-it:hover .badge-tooltip {
    
    /*top: inherit;*/
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
}

.badge-it svg {
    fill: #fff;
}

.badge-it.featured-badge {
    background: #1da1f2;
}

.tooltip {
  pointer-events: none;
}

.section.workpod-features {
    padding: 15px 0;
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 1;
    cursor: pointer;
}

.workpod-features-inner {
    width: 100%;
}

.wf-box {
    width: 100%;
    display: flex;
    align-items: center;
}

.wf-box span {
    width: 40px;
    display: flex;
    height: 50px;
    margin-bottom: 0;
    margin-right: 15px;
    flex: 0 0 auto;
}

.wf-box h4 {
    font-size: 16px;
    width: 100%;
    text-align: left;
    color: #fff;
}

.advance-filter-wrap {
    background-color: #fff;
    padding: 3px 30px 15px;
    border: 1px solid #d8dce1;
    border-radius: 4px;
    z-index: 3;
    width: 100%;
}

.filter-wrap {
    padding: 10px 0;
    border-bottom: 1px dashed #d8dce1;
}

/* inner page ends */

/* details page start */

.property-details-content-area .block {
    border-bottom: 1px dotted #d8dce1;
}

.block-body {
    padding: 40px;
    position: relative;
    display: inline-block;
    width: 100%;
}

.breadcrumb {
    padding: 8px 0;
    margin-bottom: 0;
    list-style: none;
    background-color: #fff;
    border-radius: 4px;
}

.breadcrumb li {
    font-size: 12px;
    display: inline-block;
}

.breadcrumb li a {
    position: relative;
}

.breadcrumb li:after {
    font-family: "FontAwesome";
    content: "";
    padding: 0 5px 0 10px;
    font-size: 75%;
}

.breadcrumb>.active {
    color: #777;
}

.listing-title {
    margin: 3px 0 5px 0;
    width: 85%;
}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.listing-title .label {
    top: -3px;
}
.label-featured {
    background-color: #54c4d9;
    color: #ffffff;
}
.label {
    padding: 2px 4px;
}
.label {
    font-size: 10px;
    line-height: 10px;
    font-weight: 700;
    text-transform: none;
    letter-spacing: normal;
}

.label-success {
    background-color: #85c341;
}

.block-top-title address {
    margin-right: 10px;
}

address, .pac-container {
    font-size: 15px;
    line-height: 25px;
    font-weight: 500;
    text-transform: none;
    letter-spacing: 0;
    font-family: Quicksand, sans-serif;
}

.title-section .avatar {
    width: 60px;
    top: 55px;
    right: 30px;
    position: absolute;
    border-radius: 50%;
}

.title-section .avatar img {
    border-radius: 100%;
}

.rating {
    color: #949ca5;
}

.rating .fa-star {
    color: #f8b42b;
}

.rating .star-text-right, .rating .star-text-left {
    margin-left: 5px;
}

h1.listing-title {
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    text-transform: none;
    letter-spacing: normal;
    color: #4f5962;
}

.content-area .block-bordered {
    border-bottom: 1px dotted #d8dce1;
}

.about-section .block-col {
    padding: 30px 20px;
    float: none;
    display: table-cell;
    height: 100%;
    text-align: center;
    padding: 30px 0;
    border-right: 1px dotted #d8dce1;
}

.block-col-25 {
    width: 25%;
}

.block-icon {
    font-size: 40px;
    line-height: 40px;
    font-weight: normal;
    text-transform: none;
    letter-spacing: normal;
}

.block-icon {
    margin-bottom: 15px;
}

.content-area .block {
    border-bottom: 1px dotted #d8dce1;
}

h2 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    text-transform: none;
    letter-spacing: normal;
}

.content-area .block-bordered {
    border-bottom: 1px dotted #d8dce1;
    display: table;
    width: 100%;
}

.block-icon {
    font-size: 40px;
    line-height: 40px;
    font-weight: normal;
    text-transform: none;
    letter-spacing: normal;
}


.block-icon .fa {
    color: #d8dce1;
}

.block-section:last-of-type {
    border-bottom: none;
}

.block-section .block-left {
    width: 25%;
    float: left;
}

.block-section .block-right {
    width: 75%;
    float: left;
}

.block-section .title {
    margin-bottom: 15px;
}

.title, .sub-title, .item-title-head .title {
    font-size: 16px;
    line-height: 28px;
}

.detail-list:last-of-type {
    margin-bottom: 0;
}

.detail-list.detail-list-2-cols {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
}

ul.detail-list li {
    text-transform: inherit;
}

.detail-list li, .detail-list dd, .detail-list dt {
    margin-bottom: 10px;
    line-height: 28px;
}

.detail-list i {
    margin-right: 10px;
}

.gallery-section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.gallery-section a {
    width: calc(25% - 2px);
    flex: 0 0 auto;
    margin-right: 2px;
    margin-bottom: 2px;
}

.gallery-section a:nth-child(4n + 4) {
    margin-right: 0;
}

.property-details-content-area {
    width: 100%;
    background: #fff;
}

.about-section .block-bordered {
    display: table;
    width: 100%;
}

.block-col-33 {
    width: 33.333%;
    float: left;
} 

.map-section {
    width: 100%;
}

.map-section iframe {
    width: 100%;
    height: 500px;
}

.what-nearby .what-nearby-left {
    float: left;
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.what-nearby .what-nearby-right {
    float: left;
    width: 50%;
    text-align: right;
}

.what-nearby dl {
    margin-bottom: 20px;
    width: 100%;
    display: inline-block;
}

.what-nearby dt {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 25px;
    font-weight: 500;
    text-transform: none;
    letter-spacing: 0;
}

.what-nearby dd {
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 25px;
    font-weight: 500;
    text-transform: none;
    letter-spacing: 0;
    color: #3b4249;
}

.what-nearby .time-review {
    color: #949ca5;
    font-size: 12px;
}

.label-success {
    background-color: #85c341;
}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.what-nearby .what-nearby-right .label {
    width: 65px;
    display: inline-block;
    margin-left: 10px;
}
.label {
    padding: 2px 4px;
}
.label {
    font-size: 10px;
    line-height: 10px;
    font-weight: 700;
    text-transform: none;
    letter-spacing: normal;
}

.what-nearby .what-nearby-right .time-review {
    width: 75px;
    display: inline-block;
}

.what-nearby .what-nearby-right .rating span {
    font-size: 12px;
}

.rules-section ul.rules_list li strong {
    float: right;
}

.slick-next {
    right: 5px;
}

.slick-prev {
    left: 5px;
}

.pr-slide.slick-slide {
    padding: 0 3px;
}

.innerpage-advance-filter {
    margin-top: 10px;
    position: absolute;
    width: calc(100% - 24px);
    left: 0;
    top: 64px;
    margin: 0px 12px;
    z-index: 11;
}

.sidebar-booking {
    width: 100%;
    background: #fff;
    border-bottom: 1px dotted #d8dce1;
}

.sb-price {
    width: 100%;
    background: #19cc66;
    padding: 20px 30px 16px 30px;
}

.sb-form {
    width: 100%;
    padding: 30px 30px;
}

.common-input.with-icon {
    width: 100%;
    position: relative;
}

.common-input.with-icon .form-control {
    padding: 6px 12px 6px 34px;
    margin-bottom: 10px;
}

.common-input.with-icon i {
    position: absolute;
    top: 13px;
    left: 13px;
}

.sb-form-submit {
    width: 100%;
}

.sb-form-submit button {
    width: 100%;
    border: none;
    background: #1da1f2;
    color: #fff;
    border-radius: 4px;
    padding: 14px 10px;
    font-weight: 600;
    font-size: 16px;
}

.sidebar-booking-footer {
    width: 100%;
    background: #fff;
    padding: 30px;
}

.sidebar-booking-footer button {
    width: 100%;
    border: 1px solid #dadada;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 13px 20px;
    color: #949ca5;
    font-weight: 600;
    font-size: 16px;
}

.book-now {
    width: 100%;
    /*text-align: right;*/
    margin-top: 15px;
}

.advance-filter-checkbox {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.filter-check {
    width: auto;
    margin-right: 25px;
}

.filter-check label {
    display: flex;
    align-items: center;
}

.filter-check label input[type="checkbox"] {
    margin-right: 6px;
}

.media {
    overflow: inherit;
}

.host-section .media-left {
    min-width: 100px;
}

.media .media-left {
    padding-right: 20px;
}

.media .media-object {
    width: 60px;
    display: flex;
}

.media .media-body {
    overflow: inherit;
    vertical-align: middle !important;
    width: 100%;
    display: table-cell;        
}

.block-head .title {
    font-size: 24px;
    line-height: 34px;
}

.profile-host-info {
    margin-bottom: 0;
}

.profile-host-info .super-host-flag {
    color: #19cc66;
}

.host-section dl {
    margin-bottom: 20px;
}

.img-circle {
    border-radius: 50%;
}

.block-head {
    padding: 40px;
}

.availability-section {
    border-bottom: 1px dotted #d8dce1;
}

.media {
    overflow: inherit;
    width: 100%;
    display: flex;
}

ul.list-inline.profile-host-info {
    display: flex;
    align-items: center;
    margin-top: 4px;
}

ul.list-inline.profile-host-info li {
    margin-right: 20px;
}

ul.list-inline.profile-host-info li address {
    margin: 0;
    font-size: 13px;
}

.block {
    color: #3b4249;
}

.block-head .title {
    font-size: 24px;
    line-height: 34px;
    color: #3b4249;
}

.btn-primary {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2;
}

.reviews-section {
    background: #f7f8f9;
    padding-top: 40px;
}

.sort-wrap {
    margin-bottom: 10px;
    padding: 20px 0 0;
    border-top: 1px dotted #d8dce1;
}

.reviews-section .alert, .reviews-section div.wpcf7-response-output {
    color: #4f5962;
}

.alert-info {
    background-color: #E3F0F6;
    border-color: #34B2E4;
    color: #34B2E4;
}

.review-block:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
}

.review-block {
    border-bottom: 1px dotted #d8dce1;
    padding: 40px 0;
}

.msg-user-info {
    margin: 0px 0 10px 0px;
}

.review-block .rating {
    margin-left: 6px;
}

.reviews-section .message-date {
    margin: 7px 0 0 -10px;
}

.similar-listing {
    width: 100%;
    padding-top: 40px;
    background: #f7f8f9;
}

.similar-listing h2.title {
    margin-bottom: 15px;
}

.reviews-section i {
    margin: 0 5px 0 10px;
}

/* details page ends */


/*.lp-slide.slick-slide {
    margin-right: 30px;
}

.lp-slide.slick-slide:nth-child(3n+3) {
    margin-right: 0;   
}*/

.lp-slide.slick-slide {
    padding: 0 15px 20px 15px;
}

.slick-slide {
    outline: none;
}

.landing-pod-slider .slick-next {
    right: -30px;
}

.landing-pod-slider .slick-prev {
    left: -30px;
}

.landing-pod-slider .slick-prev,.landing-pod-slider .slick-next {
    width: 30px;
    height: 30px;
}

.landing-pod-slider .slick-prev:before, .landing-pod-slider .slick-next:before {
    font-size: 30px;
    color: #7d7d7d;
}

.innerpage-filter-wrap .ms-input.select-icn select {
    padding-left: 37px;
}

.price-tags {
    width: 100%;
}

.price-tags a {
    width: auto;
    color: #222;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    padding: 8px 20px;
    background: #eee;
    min-width: 107px;
    flex: 0 0 auto;
    display: inline-block;
}

.filter-check {
    margin-right: 5px;
    background-color:#EFEFEF;
    border-radius:4px;
    overflow:hidden;
    position: relative;
    float:left;
    margin-bottom: 5px;
    /*transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;*/
}

.filter-check:hover {
    background: #1da1f2;
    border-color: transparent;
    color: #fff;
    /*transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;*/
}

.filter-check:hover label span {
    border-color: transparent;
    /*transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;*/
}

.filter-check label {
    float:left;
    width:auto;
    cursor: pointer;
    margin: 0;
}

.filter-check label span {
    text-align: center;
    padding: 8px 29px 8px 8px;
    display: block;
    border:1px solid #D0D0D0;
    position: relative;
    /*transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;*/
}

.filter-check label input {
    position:absolute;
    top:-30px;
}

.filter-check input:checked + span {
    background-color: #1da1f2;
    color: #fff;
    border-color: #1da1f2;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
}

.filter-check label span:after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    top: -50%;
    right: 8px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    opacity: 0;
}

.filter-check input:checked + span:after {
    right: 8px;
    top: 50%;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    opacity: 1;
}

.advance-filter-btn {
    /*margin-top: 10px;*/
}

.listing-sort {
    display: flex;
    align-items: center;
    width: 250px;
}

.map-outer {
    width: 100%;
    display: flex;
    height: calc(100vh - 16px);
    position: relative;
}

.close-map-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    background: #fff;
    color: #222;
    padding: 8px 14px;
    font-size: 16px;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
}

.map-outer iframe {
    width: 100%;
}

.modal-login-form.register .form-control.email-input-1 {
    border-radius: 4px;
    border-bottom: 1px solid #ced4da;
    /*margin-bottom: 15px;*/
}

.input-tagline {
    font-size: 12px;
    color: #777;
}

.mb-15 {
    margin-bottom: 15px !important;
}

/*.listing-scroll {
    height: calc(100vh - 160px);
    overflow: auto;
}*/

.map-fixed {
    padding-left: 0;
}

span.badge-it.favorite {
    background: #fff;
    text-align: center;
    font-size: 17px;
    margin-left: 5px;
    color: #1da1f2;
}

.homepage .navigation ul li.support a svg {
    fill: #fff;
}

.filter-check label span svg {
    width: 20px;
    height: 17px;
    fill: #333;
    margin-right: 5px;
}

.filter-check label span i {
    margin-right: 5px;
}

.filter-check input:checked + span svg {
    fill: #fff;
}

.filter-check:hover label span svg {
    fill: #fff;
}

.landing-main-search {
    width: 100%;
    position: relative;
}

.autocomplete-dropdown-container {
    width: 100%;
    position: absolute;
    top: 100%;
    border-radius: 4px;
    box-shadow: 5px 5px 10px 0 rgb(0 0 0 / 10%);
    z-index: 999;
}

.autocomplete-dropdown-container .suggestion-item , .autocomplete-dropdown-container .suggestion-item--active {
    padding: 10px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    text-align: left;
}

.autocomplete-dropdown-container .suggestion-item:hover {
    text-align: left;
}

.autocomplete-dropdown-container .suggestion-item span, .autocomplete-dropdown-container .suggestion-item--active span {
    display: flex;
    align-items: center;
}

.ms-input .autocomplete-dropdown-container .suggestion-item i, .ms-input .autocomplete-dropdown-container .suggestion-item--active i {
    position: static;
    width: 30px;
    padding-left: 4px;
}

.popover {
    max-width: 666px;
}

.item-favorite {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 16px;
    color: #a7a4a4;
}

.item-favorite:hover , .item-favorite.active {
    color: #1da1f2;
}

.lm-slider {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.lm-slider .swiper-button-prev, .lm-slider .swiper-button-next {
    width: 36px;
    height: 36px;
    background: #fff;
    border-radius: 100%;
    color: #000;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    -moz-box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    opacity: 0;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
}

.lm-slider:hover .swiper-button-prev, .lm-slider:hover .swiper-button-next {
    opacity: 1;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
}

.lm-slider .swiper-button-prev.swiper-button-disabled,.lm-slider .swiper-button-next.swiper-button-disabled {
    opacity: 0;
}

.lm-slider:hover .swiper-button-prev.swiper-button-disabled,.lm-slider:hover .swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
}

.lm-slider .swiper-button-prev:focus, .lm-slider .swiper-button-next:focus , .lm-slider .swiper-button-prev:active, .lm-slider .swiper-button-next:active {
    outline: none;
}

.lm-slider .swiper-button-prev:after, .lm-slider .swiper-button-next:after {
    font-size: 14px;
}

.covid-info {
    width: 100%;
    margin-bottom: 15px;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 10%);
    border-left: 3px solid #1da1f2;
    font-size: 16px;
    font-weight: 400;
    color: #222;
}

.listing-price .fpb-price {
    color: #000;
}

.pod-grid-main .fpb-image {
    height: auto;
    min-height: 205px;
}

.pod-grid-main .listing-price {
    margin-top: 15px;
}

.pod-grid-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.pod-grid-footer .listing-price {
    margin: 0;
}

.pod-grid-footer .book-now {
    margin-top: 0;
    width: auto;
}

.pod-grid-main .featured-pod-box {
    margin-bottom: 30px;
}

.right-part {
    display: flex;
    width: auto;
    align-items: center;
}

.map-view-btn {
    margin-left: 25px;
}

.map-view-btn a {
    font-size: 16px;
    color: #222;
}

.pod-grid-main .item-favorite {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    background: rgba(0,0,0,0.5);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: #fff;
}

.pod-grid-main .item-favorite.active {
    color: #1da1f2;
    background: rgba(255,255,255,0.5);
}

.list-rev {
    width: 100%;
    display: flex;
}

.list-rev .list-inline.rating {
    margin-right: 15px;
}

.list-rev a {
    font-size: 13px;
    color: #333;
    background: #e4e4e4;
    padding: 1px 8px;
    border-radius: 4px;
}

.inline-otp {
    width: 100%;
    display: flex;
}

.inline-otp .form-control {
    width: 52px;
    margin-right: 10px;
}

.more-option-wrap {
    width: 100%;
}

.mow-wrap {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
}

.mow-wrap h5 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
}

.radio-list-custom {
    width: 100%;
}

.radio-list-custom ul {
    width: 100%;
}

.radio-list-custom ul li {
    width: 100%;;
    border-bottom: 1px solid #eee;
}

.radio-list-custom ul li label {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    padding: 15px 0;
}

.rl-icon {
    flex: 0 0 auto;
    width: 30px;
    margin-right: 10px;
}

.rl-icon i {
    font-size: 24px;
    color: #333;
}

.rl-text {
    width: auto;
}

.rl-text p {
    font-size: 16px;
    color: #222;
}

.rl-text span {
    font-size: 13px;
    color: #777;
}

.radio-list-custom ul li label input {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.radio-list-custom ul li label:hover .custom-radio-circle {
    border-color: #222;
}

.custom-radio-circle {
    position: absolute;
    right: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    border: 1px solid #777;
    border-radius: 100%;
    transform: translateY(-50%);
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
}

.custom-radio-circle:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    background: #fff;
    border-radius: 100%;
    top: 50%;
    transform: translate(-50%,-50%);
    left: 50%;
    border: 6px solid transparent;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
}

.radio-list-custom ul li label input:checked + .custom-radio-circle:after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    background: #fff;
    border-radius: 100%;
    top: 50%;
    transform: translate(-50%,-50%);
    left: 50%;
    border: 6px solid #222;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
}

.resend-code-btn {
    margin-top: 30px;
}

.comm-text {
    width: 100%;
}

.comm-text h4 {
    font-size: 17px;
    color: #222;
    margin-bottom: 5px;
}

.comm-text p {
    font-size: 13px;
    color: #444;
}

.comm-text h6 {
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 5px;
}

.comm-btn {
    margin-top: 20px;
}

.btn-outline-primary {
    color: #1da1f2;
    border-color: #1da1f2;
} 

.add-place-wrap {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 50px;
}

.add-place-inner {
    width: 100%;
}

.add-place-content {
    width: 100%;
}

.apc-heading {
    width: 100%;
    margin-bottom: 20px;
}

.apc-heading h2 {
    font-size: 32px;
    font-weight: normal;
    line-height: 48px;
}

.apc-heading h3 {
    font-size: 22px;
    font-weight: normal;
    line-height: 38px;
}

.apc-inner-content {
    width: 100%;
}

.apc-inner-content h4 {
    font-weight: 500;
    margin-bottom: 10px;
}

.mb-10 {
    margin-bottom: 10px !important;
}

body.add-place-page {
    padding-top: 0;
}

.add-place-header {
    width: 100%;
}

.aph-inner {
    width: 100%;
    display: flex;
    align-items: center;
}

.aph-inner .logo {
    width: 140px;
    margin-right: 20px;
}

.aph-inner span {
    font-size: 18px;
    color: #333;
}

.add-place-progressbar {
    width: 100%;
    height: 2px;
    background: #edeff1;
}

.fill {
    width: 30%;
    height: 100%;
    background: #1da1f2;
}


.apc-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #eee;
    padding-top: 15px;
}

.apc-info-box {
    width: 100%;
    border: 2px solid #eee;
    border-radius: 6px;
    padding: 30px;
}

.apc-info-box i {
    font-size: 40px;
    color: #777;
    margin-bottom: 15px;
}

.apc-info-box h5 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.custom-num-outer {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.custom-num-outer label {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.custom-num-outer .number-input {
    margin-left: auto;
}

.number-input {
    display: flex;
    align-items: center;
}

.minus , .plus {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #709BB4;
    border-radius: 100%;
    color: #709bb5;
    cursor: pointer;
}

.number-input input {
    width: 50px;
    text-align: center;
    font-size: 16px;
    padding: 0;
    height: auto;
    line-height: 18px;
    border: none;
    font-weight: 600;
}

.plus {
    color: #1da1f2;
    border-color: #1da1f2;
}

.sleeping-arrangement {
    width: 100%;
}

.sleeping-arrangement h4 {
    font-size: 20px;
    color: #333;
}

.add-beds {
    width: 100%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #eee;
    margin-top: 20px;
}

.ab-ct {
    width: auto;
    display: flex;
    flex-direction: column;
}

.use-loc-btn {
    margin-bottom: 30px;
}

.place-map-outer {
    width: 100%;
}

.place-map-outer p {
    font-size: 18px;
    color: #444;
}

.place-map {
    margin-top: 15px;
    position: relative;
}

.place-map iframe {
    width: 100%;
    height: 300px;
}

.place-map a {
    position: absolute;
    right: 10px;
    top: 10px;
    background: #fff;
    color: #222;
    font-size: 14px;
    padding: 10px 15px;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    font-weight: 600;
}

.checkbox-list-tag {
    width: 100%;
}

.checkbox-list-tag .custom-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.checkbox-list-tag .custom-control .custom-control-label {
    font-size: 16px;
    color: #222;
    font-weight: 500;
    cursor: pointer;
}

.custom-control-tagline {
    color: #777;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.acc-text {
    width: 100%;
    margin-top: 20px;
    display: inline-block;
    padding-bottom: 20px;
}

.acc-text h4 {
    font-size: 20px;
}

.acc-text p {
    font-size: 14px;
    margin-bottom: 15px;
}

.add-photo-wrap {
    width: 100%;
    display: flex;
    padding-bottom: 50px;
}

.image-holder {
    width: 150px;
    height: 150px;
    position: relative;
    flex: 0 0 auto;
    overflow: hidden;
    border-radius: 100%;
}

.image-holder img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 100%;
}

.image-upload-btn {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    justify-content: center;
}

.btn-fb , .btn-fb:hover {
    background: #3b5998;
    color: #fff;
}

.image-upload-btn .btn {
    margin-bottom: 10px;
}

.image-upload-btn .btn:last-child {
    margin-bottom: 0;
}

.btn-icon i {
    margin-right: 8px;
}

.ckc-submit button.btn-back {
    float: left;
}

.input-btn-group {
    width: 100%;
    margin-bottom: 30px;
}

.addition-rules-option {
    width: 100%;
}

.addition-rules-option > h6 {
    font-weight: 600;
    color: #444;
    margin-bottom: 15px;
}

.addition-rules-option .custom-control {
    margin-bottom: 10px;
}

.guest-checkin {
    width: 100%;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #eee;
}

.guest-checkin h6 {
    margin-bottom: 15px;
    font-weight: 600;
    color: #444;
}


.notification-menu .dropdown-menu {
    min-width: 22em;
}
  
.notification-inner {
    width: 100%;
    max-height: 237px;
    overflow: auto;
}
  
.notification-inner .dropdown-item {
    padding: 0;
    border-radius: 0;
}
  
.notification-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 7px;
    border-bottom: 1px solid #dfdfdf;
}
  
.notification-content {
    width: 100%;
    display: flex;
    position: relative;
    padding: 7px 100px 7px 25px;
}

.noti-check {
    position: absolute;
    left: 7px;
    top: 8px;
}
  
.notification-actions {
    width: auto;
    position: absolute;
    right: 5px;
    top: 7px;
    display: flex;
}
  
.notification-actions a {
    font-size: 15px;
    color: #1da1f2;
    width: 21px;
    display: flex;
    height: 21px;
    padding: 0;
    align-items: center;
    justify-content: center;
}
  
.notification-text {
    width: 100%;
    font-size: 13px;
    color: #000;
    white-space: normal;
    word-break: break-word;
}
  
.notification-item.unread .notification-text {
    font-weight: bold;
}
  
.notification-footer {
    width: 100%;
    padding: 0 10px;
    display: flex;
    justify-content: flex-end;
}
  
.notification-item.unread {
    background: #f7f7f7;
}
  
.notification-footer a {
    font-size: 13px;
    color: #777;
    margin-left: 10px;
}
  
.notification-footer a i {
    margin-right: 5px;
}
  
.notification-inner a {
    cursor: pointer;
}
  
.notification-inner a:hover , .notification-inner a:focus {
    text-decoration: none;
}
  
.notification-actions a.note i {
    color: #ffa80a;
    margin-left: 0;
}
  
.notification-actions a.mark-as-unread i {
    color: #007ad9;
    margin-left: 0;
}
  
.notification-actions a.view-policy i {
    margin-left: 0;
    color: #008c99;
} 

.notification-actions a.delete i {
    color: #ff1010;
    margin-left: 0;
}
  
.notification-menu .dropdown-menu {
    border-radius: 0;
    max-width: 36em;
}
  
.notification-pager {
    width: 100%;
    display: flex;
    padding: 0 0;
    justify-content: flex-end;
    border-top: 1px solid #dfdfdf;
}
  
.notification-pager a {
    display: flex;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    border: 1px solid #dfdfdf;
    border-width: 0 1px 0 0px;
    color: #333;
}
  
.notification-pager a:hover {
    text-decoration: none;
}
  
.notification-pager a.np-next {
    border-left: 1px solid #dfdfdf;
}
  
.notification-pager a.active {
    background: #1da1f2;
    color: #fff;
}
  
.noti-badge {
    font-size: 9px;
    color: #fff;
    background: #1da1f2;
    border-radius: 40px;
    min-width: 18px;
    min-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 20px;
    position: absolute;
    padding: 0 4px 0 2px;
    top: 23px;
    right: -7px;
}
  
.notification-menu li a.nav-link i {
    font-size: 19px;
    color: #3a4248;
}
  

.notification-header {
    width: 100%;
    padding: 6px 6px;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
}
  
.notification-header .select-all-notification {
    width: auto;
    color: #1da1f2 !important;
    margin-right: auto;
}
  
.notification-header .select-all-notification i {
    margin-right: 5px;
}
  
.notification-header .mark-as-unread {
    font-size: 14px;
    color: #1da1f2 !important;
    width: 20px;
    text-align: center;
    margin-left: 5px;
}
  
.dropdown-item .noti-check i {
    margin-left: -5px;
}
  
.color-blue {
    color: #1da1f2 !important;
}

.fpb-amenities ul li svg {
    width: 14px;
    height: auto;
    fill: #525252;
}

.host-dashboard-outer {
    width: 100%;
    padding: 50px 0;
}

.host-page-heading {
    width: 100%;
    margin-bottom: 20px;
}

.host-page-heading h2 {
    font-size: 30px;
    color: #444;
}

.requests {
    width: 100%;
    border: 1px solid #eee;
    border-radius: 6px;
    margin-bottom: 30px;
}

.rq-box {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #eee;
}

.rq-box:last-child {
    border-bottom: none;
}

.rq-box-head {
    width: 100%;
    margin-bottom: 15px;
}

.rq-box-head h4 {
    font-size: 20px;
    color: #19cc66;
    margin-bottom: 5px;
}

.rq-box-head p {
    width: 100%;
}

.rq-box-head p span {
    font-size: 14px;
    color: #444;
    margin-right: 30px;
    position: relative;
}

.rq-box-head p span:after {}

.rq-box-message {
    width: 100%;
    font-size: 16px;
    color: #444;
    margin-bottom: 15px;
}

.rq-box-footer {
    width: 100%;
}

.rq-box-footer .btn {
    margin-right: 10px;
}

.requests:last-child {
    margin-bottom: 0;
}

.requests h3 {
    font-size: 22px;
    color: #444;
    margin-bottom: 15px;
}

.new-request-listing {
    width: 100%;
}

.requests-tab-nav.nav-tabs {
    border: none;
    margin-bottom: 20px;
}

.requests-tab-nav.nav-tabs .nav-link.active {
    border-bottom-color: #1da1f2;
    color: #1da1f2;
}

.requests-tab-nav.nav-tabs .nav-link {
    border: none;
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
    margin-right: 30px;
    color: #444;
    border-bottom: 2px solid transparent;
    font-weight: 600;
}

.my-account-dropdown ul {
    width: 180px;
    display: flex;
    flex-direction: column;
}

.my-account-dropdown ul li {
    width: auto;
}

.my-account-dropdown ul li a {
    font-size: 16px;
    color: #222;
    padding: 10px 15px;
    display: flex;
    border-bottom: 1px solid #eee;
}

.my-account-dropdown ul li:last-child {
    border-bottom: none;
}

.mt-20 {
    margin-top: 20px;
}

.listing-booked-date p {
    font-size: 16px;
    font-weight: 600;
}

.date-selection {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    padding-top: 20px;
    justify-content: center;
}

.date-select-box {
    width: auto;
    display: flex;
    background: #e2e2e2;
    border-radius: 50px;
    padding: 3px;
}

.date-select-box button {
    border: none;
    background: none;
    font-size: 14px;
    color: #444;
    padding: 10px 20px;
    border-radius: 50px;
}

.date-select-box button.active {
    background: #fff;
    color: #222;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
}

.place-info.multi {
    max-width: 33%;
    width: 100%;
}

.featured-pod-box.with-map {
    max-width: 300px;
}

.featured-pod-box.with-map .fpb-image {
    height: 201px;
}

.menu-toggle {
    display: none;
}

.checkout-outer {
    width: 100%;
    padding: 50px 0;
}

.checkout-outer {
    width: 100%;
    padding: 50px 0;
}

.ck-heading {
    width: 100%;
    margin-bottom: 20px;
}

.ck-heading h2 {
    font-size: 30px;
}

.ck-trip {
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.ck-trip h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.ck-trip-row {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.crt-info {
    width: auto;
}

.crt-info p {
    font-size: 18px;
    font-weight: 600;
}

.crt-info span {
    font-size: 15px;
    color: #333;
}

.crt-edit {
    margin-left: auto;
}

.crt-edit a {
    font-size: 16px;
    font-weight: 600;
    color: #222;
    text-decoration: underline;
}

.ck-pay {
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.ck-pay h3 {
    font-size: 24px;
    margin-bottom: 20px;
}

.ck-pay .form-control {
    margin-bottom: 10px;
}

.ck-pay a {
    font-size: 16px;
    color: #222;
    text-decoration: underline;
    font-weight: 600;
}

.ck-cancellation-policy {
    width: 100%;
    border-left: 5px solid #ff9800;
    padding-left: 20px;
    margin-bottom: 25px;
}

.ck-cancellation-policy h3 {
    font-size: 24px;
    margin-bottom: 20px;
}

.ck-cancellation-policy p {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
}

.ck-cancellation-policy p:last-child {
    margin-bottom: 0;
}

.ck-cancellation-policy p a {
    font-weight: 600;
    color: #222;
    text-decoration: underline;
}

.reserve-warning {
    width: 100%;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    padding: 20px 0;
    position: relative;
    padding-left: 60px;
}

.reserve-warning i {
    position: absolute;
    left: 0;
    top: 24px;
    font-size: 40px;
    color: #333;
}

.reserve-warning h4 {
    font-size: 16px;
}

.reserve-warning p {
    font-size: 16px;
    color: #333;
}

.ck-policy {
    padding: 20px 0;
}

.ck-policy p {
    color: #000;
}

.ck-book-box-wrap {
    width: 100%;
    padding: 20px;
    border: 1px solid #dfdfdf;  
    border-radius: 10px;
    background-color: #fff;
}

.ck-bookinfo {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dfdfdf;
}

.ck-bookinfo-image {
    width: 124px;
    flex: 0 0 auto;
    height: 106px;
    border-radius: 10px;
    overflow: hidden;
}

.ck-bookinfo-details {
    width: 100%;
    padding-left: 15px;
}

.ck-bookinfo-details span {
    color: #000;
    margin-bottom: 5px;
    display: flex;
}

.ck-bookinfo-details p {
    font-size: 14px;
    color: #000;
    font-weight: 500;
}

.ck-bookinfo-details small {
    font-size: 13px;
    color: #333;
    margin-top: 5px;
    display: flex;
}

.star {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 8px;
}

.star i {
    width: auto;
    flex: 0 0 auto;
    margin-right: 5px;
}

.star p {
    width: auto;
    display: flex;
}

.star p span {
    margin: 0;
    color: #777;
}

.ck-price {
    width: 100%;
    font-weight: 600;
}

.ck-price > h3 {
    font-size: 24px;
    margin-bottom: 20px;
}

.ck-price-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.ck-price-row:last-child {
    margin-bottom: 0;
}

.ck-price-row p {
    font-size: 16px;
    color: #222;
}

.ck-price-row span {
    font-size: 16px;
    color: #222;
}

.ck-price-row a {
    font-size: 16px;
    color: #222;
    text-decoration: underline;
}

.property-photo-add {
    width: 100%;
    padding-bottom: 30px;
}

.property-photo-add input.form-control {
    padding: 3px 10px;
    width: 100%;
    display: inline-block;
}

.added-pic {
    width: 100%;
}

.added-pic .row {
    margin-left: -5px;
    margin-right: -5px;
}

.added-pic .col-lg-3.col-md-3 {
    padding: 0 5px;
}

.property-photo-add input.form-control {
    padding: 3px 10px;
    margin-bottom: 10px;
}

.reg-summary-outer {
    width: 100%;
    padding: 50px 0;
}

.reg-summary {
    width: 100%;
}

.reg-summary p {
    font-size: 16px;
}

.reg-summary ul {
    width: 100%;
    margin-top: 30px;
}

.reg-summary ul li {
    width: 100%;
    position: relative;
    padding-left: 30px;
    margin-bottom: 25px;
}

.reg-summary ul li i {
    position: absolute;
    left: 0;
    top: 5px;
    font-size: 16px;
    color: #dfdfdf;
}

.reg-summary ul li.completed i , .reg-summary ul li.active i {
    color: #1da1f2;
}

.reg-summary ul li p {
    font-size: 18px;
    color: #777;
}

.reg-summary ul li.completed p , .reg-summary ul li.active p {
    color: #222;
    font-weight: 600;
    text-decoration: underline;
}

.place-info.multi-date-sel {
    width: 375px;
}

.how-we-work-outer {
    width: 100%;
}

.how-work-box {
    width: 100%;
    height: 500px;
}

.how-work-box .row {
    height: 100%;
}

.hw-img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hw-img img {
    width: auto;
    max-height: 400px;
}

.hw-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 80px;
}

.hw-content h2 {
    font-size: 38px;
    line-height: 52px;
    color: #1da1f2;
    margin-bottom: 30px;
}

.hw-content p {
    font-size: 18px;
    color: #444;
}

.hw-head {
    width: 100%;
    margin: 30px 0;
    text-align: center;
    font-size: 32px;
    color: #222;
}

.comming-soon {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comming-soon p {
    font-size: 40px;
    color: #444;
}

.block-icon svg {
    fill: #3b4249;
    width: 70px;
    height: 50px;
}

.sidebar-booking .common-input.with-icon {
    margin-bottom: 10px;
}

.privacy-text {
    width: 100%;
    color: #484848;
    padding: 50px 0;
}

.privacy-text h2 {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 25px;
}

.privacy-text p {
    font-size: 16px;
    margin: 1em 0;
}

.privacy-text h3 {
    font-size: 18px;
    margin: 1em 0;
    width: 100%;
    display: inline-block;
}

.privacy-text-box {
    width: 100%;
    background: #eee;
    padding: 0 1em 1px 1em;
}

.privacy-text ul {
    width: 100%;
    padding-left: 20px;
}

.privacy-text ul li {
    position: relative;
    padding-left: 20px;
    font-size: 16px;
    margin-bottom: 5px;
}

.privacy-text ul li:before {
    content: "";
    width: 8px;
    height: 8px;
    background: #484848;
    position: absolute;
    left: 0;
    top: 8px;
    border-radius: 100%;
}

.blog-outer , .places-outer {
    width: 100%;
    padding: 50px 0 20px 0;
}

.blog-outer .blog-box {
    margin-bottom: 30px;
}

.aboutus-outer {
    width: 100%;
    padding: 50px 0;
    color: #484848;
}

.about-inner {
    width: 100%;
}

.about-inner h2 {
    font-size: 32px;
    width: 100%;
    text-align: center;
    color: #222;
}

.about-text {
    width: 100%;
    margin-top: 30px;
}

.about-text p {
    font-size: 16px;
    margin: 1em 0;
}

.about-text h3 {
    font-size: 18px;
    font-weight: 600;
    color: #222;
}

.about-contact-box {
    width: 100%;
    padding: 50px 0;
    background: #1da1f2;
    margin-top: 40px;
}

.ac-inner {
    width: 100%;
    text-align: center;
}

.ac-inner h2 {
    font-size: 30px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 20px;
}

.ac-inner a {
    background: #19cc66;
    color: #fff;
    padding: 10px 50px;
    display: inline-block;
    border-radius: 6px;
    font-size: 16px;
}

.ac-inner p {
    width: 100%;
    margin-top: 40px;
    font-size: 22px;
    color: #fff;
}

.ac-inner p span {
    font-weight: bold;
}

.about-places {
    width: 100%;
    padding-top: 50px;
}

.about-places h2.h2 {
    text-align: center;
    margin-bottom: 30px;
}

.h2 {
    width: 100%;
    font-size: 32px;
    color: #222;
    font-weight: 600;
}

.contact-outer {
    width: 100%;
}

.contact-heading {
    width: 100%;
    background: #1da1f2;
    padding: 30px 0;
    margin-top: 3px;
}

.contact-heading .h2 {
    margin: 0;
    text-align: center;
    color: #fff;
}

.contact-form {
    width: 100%;
    padding: 50px 0;
}

.inline-radio {
    width: 100%;
}

.inline-radio .custom-control-inline {
    margin-right: 50px;
}

.contact-form .custom-control-label {
    font-size: 16px;
}

.contact-form .inline-radio {
    margin-bottom: 30px;
}

.contact-form .form-group {
    margin-bottom: 30px;
}

.pr-text {
    width: 100%;
}

.pr-text p {
    margin-bottom: 10px;
    color: #484848;
}

.ct-submit {
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

.ct-submit button {
    font-size: 16px;
    padding: 10px 50px;
}

.faq-inner {
    width: 100%;
    padding: 50px 0;
}

.faq-inner h3 {
    font-size: 24px;
    color: #484848;
    font-weight: 600;
    margin-bottom: 20px;
}

.faq-inner .card-header {
    cursor: pointer;
    font-size: 16px;
}

.faq-inner .card-body {
    font-size: 16px;
}

.hw-content.pl-none {
    padding-left: 0;
    padding-right: 80px;
}

.wp-feature .hw-img img {
    max-height: 240px;
}

.how-work-box.wp-feature {
    height: 330px;
}

.wp-feature .hw-content h2 {
    font-size: 28px;
    line-height: 38px;
}

.contact-text {
    width: 100%;
    font-size: 16px;
    color: #444;
    margin-bottom: 30px;
}

.contact-image {
    width: 100%;
    height: 100%;
    padding: 50px 30px 0 30px;
}

.host-section-buttons a {
    margin-right: 10px;
}

body.homepage img.logo-color {
    display: none;
}

.add-place-content .filter-check {
    width: 100%;
    margin-right: 0;
}

.add-place-content .filter-check label {
    width: 100%;
}

.add-place-content .filter-check label span {
    width: 100%;
    text-align: left;
}

.thumb-inner a {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #ff0000;
    background: rgba(0,0,0,0.5);
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 12px;
    cursor: pointer;
}

.thumb-inner {
    position: relative;
}

.mobile-filter-toggle {
    display: none;
}

.apc-heading h5 {
    font-size: 15px;
    font-weight: normal;
}

.wizard-step {
    width: 100%;
    margin-bottom: 50px;
}

.wizard-step ul {
    width: 100%;
    display: flex;
    justify-content: center;
}

.wizard-step ul li {
    width: 200px;
}

.wizard-step ul li a {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.wizard-step ul li a:after {
    content: "";
    width: 100%;
    height: 2px;
    background: #1da1f2;
    position: absolute;
    right: -102px;
    top: 14px;
}

.wizard-step ul li:last-child a:after {
    display: none;
}

.wizard-step ul li a span {
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    border: 2px solid #1da1f2;
    border-radius: 100%;
    margin-bottom: 8px;
    background: #fff;
    z-index: 1;
}

.wizard-step ul li a p {
    font-size: 14px;
    color: #484848;
}

.ck-btn-login {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
}

.ck-btn-login button {
    width: 300px;
    margin-bottom: 10px;
}

.ck-btn-login button:last-child {
    margin-bottom: 0;
}

.or {
    width: 100%;
    border-top: 1px solid #dfdfdf;
    text-align: center;
    position: relative;
}

.or span {
    position: relative;
    background: #fff;
    top: -10px;
    padding: 0 30px;
    font-weight: 600;
    color: #484848;
}

.ck-create-acc {
    width: 100%;
    padding-top: 30px;
}

.ck-create-acc h2 {
    width: 100%;
    text-align: center;
    color: #484848;
    margin-bottom: 6px;
}

.ck-create-acc p {
    width: 100%;
    text-align: center;
    color: #484848;
}

.ck-create-acc .row.justify-content-center {
    margin-top: 30px;
}

.ckc-submit {
    width: 100%;
    text-align: center;
}

.ckc-submit .privacy-text {
    padding-top: 20px;
}

.ck-booking-confirm-outer {
    width: 100%;
}

.ckb-box {
    width: 100%;
    text-align: center;
}

.ckb-box img {
    width: 100px;
    margin-bottom: 30px;
}

.ckb-box h2 {
    color: #484848;
    font-weight: 500;
    font-size: 26px;
    margin-bottom: 8px;
}

.ckb-box span {
    font-size: 18px;
    font-weight: 600;
    color: #222;
    margin-bottom: 15px;
    display: inline-block;
}

.ckb-box p {
    font-size: 16px;
    color: #444;
    margin-bottom: 15px;
}

.ckb-cancel-warning {
    width: 100%;
    padding: 30px;
    border: 1px solid #777;
    border-radius: 6px;
    margin-top: 40px;
    background: #f7fbfd;
    text-align: center;
    color: #252f35;
}

.ckb-cancel-warning h4 {
    font-size: 18px;
    margin-bottom: 20px;
}

.ckb-cancel-warning p {
    font-size: 16px;
}

.ck-booking-confirm-outer .privacy-text {
    padding: 30px 0;
    text-align: center;
}

.wizard-step ul li a.completed span {
    background: #007bff;
    border-color: #007bff;
    text-indent: -99999px;
    position: relative;
}

.wizard-step ul li a.completed span:after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    color: #fff;
    position: absolute;
    text-indent: 0px;
    font-weight: 900;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.wizard-step ul li a.active span {
    width: 40px;
    height: 40px;
    margin-top: -4px;
    font-size: 18px;
    background: #1da1f2;
    color: #fff;
}

.wizard-step.hosting-wizard ul li a:after {
    right: -92px;
}

.suggestion-item {
    position: relative;
}

.selected-date {
    display: flex;
    align-items: center;
    height: 37px;
}

.selected-date a {
    width: auto;
    margin-right: 10px;
    display: flex;
}

.selected-date a i {
    position: static;
    font-size: 15px;
}

.selected-date h6 {
    font-weight: normal;
    font-size: 14px;
}

.table-filter {
    padding: 20px 30px 0 30px;
    margin-bottom: 30px;
}

.podlisting-table {
    width: 100%;
    padding: 0 30px;
}

.podlisting-table .p-datatable {
    border: 1px solid #dfdfdf;
}

.view-btn {
    width: 30px;
    height: 30px;
    display: flex;
    border: 1px solid #dfdfdf;
    color: #333;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.view-btn i {
    font-size: 13px;
}

.tbl-actions {
    display: flex;
    align-items: center;
}

.ml-5 {
    margin-left: 5px !important;
}

.tbl-actions a.view-btn {
    margin-left: 10px;
}   

.hero-main-slider {
    width: 100%;
    height: 100%;
}

.hero-main-slider .swiper-container {
    height: 100%;
}

.hero-slide {
    width: 100%;
    height: 100%;
    position: relative;
}

.hero-slide img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    width: 100%;
}

.hc-main-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
}

.blog-detail-outer {
    width: 100%;
    padding-top: 30px;
}

.blog-detail-inner {
    width: 100%;
}

.blog-single {
    width: 100%;
}

.blog-single h2 {
    font-size: 24px;
    color: #1da1f2;
    margin-bottom: 12px;
}

.blog-single p {
    font-size: 16px;
    color: #222;
    margin-bottom: 20px;
}

.blog-single-img {
    width: 100%;
    margin-bottom: 30px;
}   

.blog-sidebar {
    width: 100%;
}

.blog-latest-post {
    width: 100%;
}

.blog-latest-post > h3 {
    font-size: 20px;
    color: #1da1f2;
    margin-bottom: 20px;
}

.lp-box {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}

.lp-box:last-child {
    margin-bottom: 0;
}

.lp-img {
    width: 100px;
    height: 56px;
    flex: 0 0 auto;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    background: #eee;
}

.lp-img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
}

.lp-details {
    width: 100%;
    padding-left: 15px;
}

.lp-details h4 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
}

.lp-details p {
    font-size: 12px;
    color: #484848;
}

.payout-method {
    margin-top: 20px;
}

.payout-method h4 {
    font-size: 22px;
    color: #484848;
    margin-bottom: 20px;
}

.payout-method ul {
    width: 100%;
    margin-top: 5px;
}

.payout-method ul li {
    width: 100%;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.payout-method ul li label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
}

.payout-method ul {
    width: 100%;
    margin-top: 5px;
}

.payout-method ul li .payout-tags li {
    position: relative;
    width: 100%;
    font-size: 12px;
    color: #484848;
    padding-left: 11px;
    margin-bottom: 4px;
    border: none !important;
    padding-bottom: 0;
}

.payout-method ul li .payout-tags li:after {
    content: "";
    width: 4px;
    height: 4px;
    position: absolute;
    background: #777;
    border-radius: 100%;
    left: 0;
    top: 7px;
}

.payment-info-text {
    width: 100%;
    color: #484848;
    padding-bottom: 15px;
}

.payment-info-text h4 {
    font-size: 22px;
    color: #222;
    margin-bottom: 15px;
}

.payment-info-text h6 {
    font-weight: 600;
    margin-bottom: 5px;
}

.payment-info-text p {
    font-size: 14px;
}

.add-bank-outer {
    width: 100%;
}

.add-bank-info {
    width: 100%;
}

.add-bank-info h4 {
    font-size: 22px;
    color: #484848;
    margin-bottom: 15px;
}

.add-bank-info .apc-heading h6 {
    color: #484848;
}

.bank-info-radio {
    margin-bottom: 15px;
}

.add-bank-info label {
    margin-bottom: 0;
}

.confirm-bank-info {
    width: 100%;
}

.confirm-bank-info h4 {
    font-size: 22px;
    color: #484848;
    margin-bottom: 15px;
}

.added-info-box {
    width: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.added-info-box h6 {
    font-size: 14px;
    font-weight: 600;
    color: #484848;
    margin-bottom: 4px;
}

.added-info-box p {
    font-size: 15px;
    color: #222;
}

.added-acc-box {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 26px;
    margin-bottom: 10px;
    padding-bottom: 0;
    border-bottom: 1px solid #dfdfdf;
}

.added-acc-box:last-child {
    border-bottom: none;
}

.added-acc-box i {
    position: absolute;
    top: 3px;
    left: 0;
    font-size: 20px;
    color: #484848;
}

.added-acc-box p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}

.remove-paypal-acc {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
}

.added-acc-box .remove-paypal-acc i {
    position: static;
}

.blog-single ul {
    width: 100%;
}

.blog-single ul li {
    position: relative;
    margin-bottom: 15px;
    padding-left: 20px;
    font-size: 14px;
}

.blog-single ul li:after {
    content: "";
    width: 5px;
    height: 5px;
    background: #484848;
    position: absolute;
    left: 0;
    top: 7px;
    border-radius: 100%;
}

.offer-outer {
    width: 100%;
}

.offer-outer h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.offer-outer label {
    margin-bottom: 4px;
}

.with-apply-btn {
    width: 100%;
    position: relative;
    padding-right: 82px;
}

.with-apply-btn button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
}

.hosting-wizard.wizard-step ul li a.completed span {
    background: #19cc66;
    border: #19cc66;
}

.hosting-wizard.wizard-step ul li a.completed:after {
    background: #19cc66;
    right: -92px;
}

.apc-inner-content .form-group input {
    height: calc(1.5em + .75rem + 5px);
}

.payment-info-text svg {
    width: 170px;
}

.prepaid-acc-outer {
    width: 100%;
}

.create-prepaid-acc {
    width: 100%;
    padding: 50px;
    background: #eef2f5;
    border-radius: 6px;
    text-align: center;
}

.create-prepaid-acc p {
    font-size: 20px;
    margin-bottom: 20px;
}

.topup-label {
    width: 100%;
}

.submit-btn {
    width: 100%;
    margin-top: 30px;
}

.submit-btn button {
    margin-right: 10px;
}

.pre-ac-info {
    width: 100%;
    padding: 30px 0;
}

.pre-ac-info h2 {
    font-size: 24px;
    font-weight: 600;
    color: #1da1f2;
    margin-bottom: 20px;
}

.table-common {
    width: 100%;
}

.table-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.table-header h3 {
    font-weight: 600;
    font-size: 18px;
    color: #484848;
}

.th-action {
    width: auto;
}

.th-action a {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dfdfdf;
    border-radius: 100%;
    color: #222;
}

.p-datatable {
    border: 1px solid #e9ecef;
}

.p-datatable .p-datatable-thead > tr > th {
    padding: 10px !important;
    font-size: 13px;
}

.p-inputtext.p-component.p-column-filter {
    font-size: 13px;
    padding: 6px 10px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    font-size: 13px;
    padding: 10px !important;
    overflow-wrap: break-word;
}

.custom-amount {
    width: 100%;
    display: inline-block;
    margin-top: 10px;
}

.pre-dashboard-info {
    width: 100%;
    max-width: 100%;
    border: 1px dashed #dfdfdf;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
}

.pre-dashboard-info span {
    width: 100%;
    padding: 0 20px;
    font-size: 13px;
    color: #333;
    margin-bottom: 10px;
}

.pre-account-balence {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
    background: #fff;
}

.pre-account-balence h3 {
    font-size: 20px;
    color: #19cc66;
    font-weight: 600;
}

.pre-dashboard-info p {
    padding: 0 20px;
    font-size: 14px;
    color: #1da1f2;
    font-weight: 500;
    margin-top: 10px;
}

.mobile-checkout {
    display: none;
}

.featured-pods .fpb-amenities {
    min-height: 72px;
}

.featured-pods .fpb-content p {
    min-height: 42px;
}

.added-acc-box .remove-acc {
    position: absolute;
    right: 0;
    top: 0;
}

.added-acc-box .remove-acc i {
    position: static;
    color: #f15252;
}

.reg-summary ul li i.fas.fa-edit {
    left: inherit;
    right: 0;
    color: #ff5722;
    cursor: pointer;
}

.item-report {
    position: absolute;
    right: 43px;
    top: 16px;
    font-size: 14px;
    color: #a7a4a4;
}

.no-pod-available {
    width: 100%;
    background: #fff;
    padding: 40px;
    border-radius: 4px;
    border: 1px dashed #dfdfdf;
}

.no-pod-available p {
    font-size: 16px;
}

.sidebar-booking-footer button.active {
    background: #1da1f2;
    border-color: #1da1f2;
    color: #fff;
}

.btn.btn-primary.pre-checkout {
    width: 100%;
    border-radius: 50px;
    padding: 10px 20px;
}

body.modal-open {
    padding-right: 0 !important;
    overflow: auto !important;
}

.slot-prices {
    width: 100%;
}

.slot-prices table {
    width: 100%;
}

.slot-prices table td.right, .slot-prices table th.right {
    text-align: right;
}

.slot-prices table th {
    font-size: 14px;
    font-weight: 600;
    color: #777;
    padding: 5px 0;
    border-bottom: 1px solid #dfdfdf;
}

.slot-prices table td {
    font-size: 14px;
    color: #222;
    font-weight: normal;
    padding: 5px 0;
}

.slot-prices table tfoot th {
    color: #000;
    border-top: 1px solid #dfdfdf;
}

.profile-outer {
    width: 100%;
}

.profile-inner {
    width: 100%;
    padding: 20px 0;
}

.profile-inner h3 {
    font-size: 26px;
    font-weight: 600;
    color: #222;
    margin-bottom: 30px;
}

.added-info {
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
}

.ai-left h6 {
    font-size: 16px;
    font-weight: 600;
    color: #444;
    margin-bottom: 6px;
}

.ai-left span {
    font-size: 16px;
    color: #222;
}

.edti-info a {
    font-size: 16px;
    color: #1da1f2;
    font-weight: 600;
}

.edit-info {
    width: 100%;
    border: 1px solid #dfdfdf;
    padding: 30px;
}

.edit-info-box {
    width: 100%;
    margin-bottom: 30px;
}

.edit-info-box:last-child {
    margin-bottom: 0;
}

.edit-info-box i {
    color: #1da1f2;
    font-size: 34px;
    margin-bottom: 15px;
}

.edit-info-box h4 {
    font-size: 18px;
    font-weight: 600;
    color: #444;
    margin-bottom: 10px;
}

.edit-info-box p {
    font-size: 13px;
    color: #777;
}

.edit-icon{
    display: inline-flex;
    font-size: 65%;
    cursor: pointer;
    margin-left: 1%;
}

.sidebar-booking-footer button.contact-host {
    background: #1da1f2;
    color: #fff;
    border-color: #1da1f2;
}

.payment-tags-outer {
    width: 100%;
    margin-top: 15px;
    display: flex;
}

.payment-tags {
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    padding: 5px 16px;
    display: flex;
    margin-right: 10px;
}

.payment-tags b {
    font-weight: 600;
}

.workpod-team {
    width: 100%;
    padding-top: 50px;
    background: #eef4f7;
    padding-bottom: 50px;
    margin-top: 30px;
}

.workpod-team .h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.team-box {
    width: 100%;
    text-align: center;
}

.team-box img {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin-bottom: 15px;
}

.team-box h4 {
    font-size: 18px;
    font-weight: 600;
    color: #222;
    margin-bottom: 5px;
}

.btn.back-btn {
    padding: 0 0;
    margin-bottom: 20px;
}

.btn.back-btn i {
    margin-right: 5px;
}

.user-pay-status {
    width: 100%;
    display: flex;
}

.user-pay-status p {
    margin-right: 10px;
}

.payment-history {
    width: 100%;
    padding: 0 20px;
    margin-top: 15px;
}

.btn.history-btn {
    background: #dfdfdf;
    font-size: 13px;
}

.history-table-head {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.h-accname {
    font-weight: 600;
    font-size: 20px;
    color: #444;
}

.h-date-filter {
    width: auto;
    margin-left: 20px;
}

.h-date-filter .p-inputtext {
    padding: 5px 9px;
    font-size: 13px;
}

.h-date-filter button.btn.btn-primary {
    font-size: 13px;
    padding: 6px 10px;
}

.export-excel {
    margin-left: 20px;
    cursor: pointer;
}

.history-table-head h6 {
    margin-left: auto;
    font-weight: 600;
    font-size: 20px;
    color: #19cc66;
}

.amenities-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
}

.amenities-list p {
    display: flex;
    align-items: center;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    padding: 7px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.amenities-list p svg {
    width: 20px;
    margin-right: 5px;
}

.amenities-list p span {
    font-size: 13px;
}

.review-block .rating .label.label-success {
    margin-left: 10px;
}

.review-cat {
    width: 100%;
    background: #fff;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.review-cat-opt {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
}

.review-prg {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rp-stripe {
    width: 110px;
    height: 4px;
    background: #ccc;
    position: relative;
    overflow: hidden;
}

.rstripe-progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 10%;
    background: #222;
}

.review-cat .row {
    margin-bottom: 15px;
}

.review-cat .row:last-child {
    margin-bottom: 0;
}

.dis-applied {
    width: 100%;
    border: 1px solid #19cc66;
    border-radius: 4px;
    background: #eaf7f0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.dis-applied p {
    font-size: 13px;
}

.dis-applied a {
    color: #222;
}

.feedback-box {
    width: 100%;
}

.feedback-box p {
    width: 100%;
    margin-bottom: 10px;
}

.feedback-box .p-radiobutton {
    margin-right: 8px;
}

.feedback-box .custom-control {
    margin-bottom: 5px;
}

.feedback-footer {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.give-feedback {
    width: 100%;
}

.give-feedback p {
    width: 100%;
    font-size: 18px;
    color: #222;
}

.p-rating .p-rating-icon:focus {
    box-shadow: none !important;
}

.mb-5px {
    margin-bottom: 5px !important;
    display: inline-block;
}

.manage-booking-buttons .btn {
    margin-top: 15px;
    font-size: 14px;
}

.flag-img svg {
    width: 35px;
}

.w-600 {
    font-weight: 600;
}

.wn-dropdown {
    position: absolute;
    top: 74px;
    background: #fff;
    width: 520px;
    right: 0;
    border-radius: 10px;
    box-shadow: 10px 10px 20px 0 rgb(0 0 0 / 20%);
    -webkit-box-shadow: 10px 10px 20px 0 rgb(0 0 0 / 20%);
    -moz-box-shadow: 10px 10px 20px 0 rgb(0 0 0 / 20%);
    overflow: hidden;
}

.wnd-head {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    border-bottom: 1px solid #dfdfdf;
}

.wn-selectall {
    width: auto;
    display: flex;
    align-items: center;
}

.wn-selectall label {
    margin: 0;
    display: flex;
    align-items: center;
}

.wn-selectall label input {
    margin-right: 7px;
    width: 15px;
    height: 15px;
}

.wnd-options {
    margin-left: auto;
    display: flex;
}

.wnd-options a {
    color: #1da1f2 !important;
    padding: 0 6px !important;
}

.wnd-content {
    width: 100%;
    min-height: 443px;
    overflow: auto;
    max-height: 443px;
}

.navigation .wnd-content ul , .wnd-content ul {
    width: 100%;
    flex-direction: column;
    display: flex;
}

.navigation .wnd-content ul li , .wnd-content ul li {
    width: 100%;
    padding: 12px 15px;
    align-items: flex-start;
    position: relative;
    border-bottom: 1px solid #dfdfdf;
    background-color: #fff;
}

.navigation .wnd-content ul li:last-child , .wnd-content ul li:last-child {
    border-bottom: none;
}

.wnd-select {
    width: 15px;
    margin-right: 10px;
    flex: 0 0 auto;
}

.wnd-select label {
    margin: 0;
}

.wnd-select label input {
    width: 15px;
    height: 15px;
}

.wnd-inner {
    width: 100%;
    display: flex;
}

.wnd-inner span {
    width: 30px;
    height: 30px;
    border: 1px solid #19cc66;
    color: #19cc66;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    flex: 0 0 auto;
}

.wnd-text {
    width: 100%;
    padding-left: 10px;
}

.wnd-text p {
    font-size: 14px;
    font-weight: 600;
    color: #222;
}

.wnd-text small {
    font-size: 13px;
}

.wnd-inner-options {
    display: flex;
    width: 56px;
    flex: 0 0 auto;
    justify-content: flex-end;
}

.wnd-inner-options a {
    color: #1da1f2 !important;
    padding: 0 6px !important;
}

.wnd-options a.wnd-delete-icon , .wnd-inner-options a.wnd-delete-icon {
    color: #f44336 !important;
}

.wnd-options a.wnd-unread-icon , .wnd-inner-options a.wnd-unread-icon {
    color: #ffc107 !important;
}

.navigation .wnd-content ul li.wnd-unread {
    background: #f3f3f3;
}

.wnd-inner span.icn-warning {
    border-color: #f44336;
    color: #f44336;
}

.dashboard-box {
    width: 100%;
    background: #fff;
    box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 20%);
    border-radius: 10px;
    margin-bottom: 30px;
}

.dashboard-box-header {
    width: 100%;
    padding: 15px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dfdfdf;
}

.dashboard-box-header h5 {
    color: #444;
    font-weight: 600;
}

.dashboard-box-content {
    padding: 15px;
}

.dashboard-box-content .wnd-content ul li {
    padding-left: 0;
    padding-right: 0;
}

.dashboard-box-content .wnd-inner-options {
    position: absolute;
    top: 11px;
    right: 0;
}

.custom-slot-slider {
    width: 100%;
    position: relative;
}

.slot-slide {
    width: 100%;
}

.slot-slide .row {
    margin-bottom: 10px;
}

.slot-select {
    width: 100%;
}

.slot-select select {
    width: 100%;
}

.slot-nav.next {
    right: -27px;
    left: inherit;
}

.slot-nav {
    position: absolute;
    top: 50%;
    left: -27px;
    font-size: 22px;
    color: #222;
    background: #e0e7ea;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    padding: 20px 5px;
    border-radius: 2px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
}

.slot-nav:hover {
    background: #1da1f2;
    color: #fff;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
}

.radio-button {
    width: 100%;
    margin-bottom: 30px;
}

.radio-button .apc-heading {
    margin-bottom: 6px;
}

.radio-button .custom-control-label {
    font-size: 16px;
}

.refund-box {
    width: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    padding: 30px;
}

.rb-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.rb-inner:last-child {
    margin-bottom:0;
}

.rb-inner p {
    font-size: 16px;
    color: #222;
}

.rb-inner span {
    font-size: 16px;
    font-weight: 600;
    color: #28a745;
}

.rb-complete {
    width: 100%;
    text-align: right;
    margin-top: 10px;
}

.db-booking {
    width: 100%;
}

.dbb-row {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    padding: 10px 0;
}

.dbb-row:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.dbb-row:first-child {
    padding-top: 0;
}

.dbb-img {
    width: 100px;
    flex: 0 0 auto;
    height: 65px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.dbb-img img {
    max-width: 100%;
}

.dbb-content {
    width: 100%;
    padding-left: 15px;
}

.dbb-content h5 {
    font-size: 16px;
    color: #222;
    font-weight: 600;
    margin-bottom: 8px;
}

.dbb-status {
    width: 100%;
    display: flex;
}

.dbb-status p {
    margin-right: 15px;
    color: #333;
}

.dbb-btn {
    width: 90px;
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-end;
}

.dbb-btn button.btn.btn-primary {
    font-size: 12px;
    padding: 6px 9px;
    background: #19cc66;
    border: #19cc66;
}

/* mobile navigation start */

.xs-navigation {
    width: 294px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 9999;
    background: #fff;
    padding-top: 30px;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 30%);
}

.xs-navigation ul {
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
}

.xs-navigation ul li {
    width: 100%;
}

.xs-navigation ul li a {
    width: 100%;
    font-size: 14px;
    color: #222;
    padding: 12px 20px;
    display: inline-block;
    border-bottom: 1px solid #dfdfdf;
}

.xs-navigation ul li ul.xs-submenu {
    padding-left: 40px;
    border-top: none;
}

.xs-navigation ul li ul.xs-submenu li a {
    border-left: 1px solid #dfdfdf;
    font-size: 13px;
    padding: 10px 12px;
}

/* mobile navigation ends */

/* responsive styles start */

@media (max-width: 767px) {

    .navigation .nav-toggle {
        display: none;
    }

    header {
        z-index: 99;
    }

    body.homepage .navigation ul li a {
        color: #222;
    }

    body.homepage .navigation ul li.host-btn a {
        color: #fff;
    }

    .menu-toggle {
        display: block;
        position: absolute;
        right: 30px;
        top: 29px;
        font-size: 26px;
        color: #333;
    }

    .homepage .menu-toggle {
        color: #fff;
    }

    .sticky-header .menu-toggle {
        color: #222;
    }

    .homepage .navigation ul li.support a svg {
        fill: #222;
    }

    .gallery-section a {
        width: calc(50% - 2px);
    }

    .navigation {
        width: 100%;
        background: #fff;
        box-shadow: 0 0 30px 0 rgb(0 0 0 / 20%);
        -webkit-box-shadow: 0 0 30px 0 rgb(0 0 0 / 20%);
        -moz-box-shadow: 0 0 30px 0 rgb(0 0 0 / 20%);
    }

    .navigation ul li {
        width: auto;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dfdfdf;
    }

    .navigation ul li a {
        padding: 11px 20px;
        width: 100%;
        justify-content: center;
    }

    .navigation ul li.support a {
        padding: 11px 20px;
    }

    .header-inner {
        flex-direction: column;
    }

    .navigation .nav-toggle.toggle-new {
        display: block;
    }

    .mobile-hide {
        display: none;
    }

    section.section {
        padding: 50px 0;
    }

    .hero-section {
        height: auto;
        padding: 100px 0 50px 0;
    }

    .hero-section-inner {
        flex-direction: column;
        justify-content: flex-start;
    }

    .hero-caption p {
        font-size: 16px;
    }

    .section.workpod-features {
        position: static;
        margin-top: 50px;
    }

    .main-search {
        flex-direction: column;
    }

    .search-place , .ms-date {
        width: 100%;
        margin: 0 0 10px 0;
    }

    .ms-search-btn {
        margin: 0;
    }

    section.hero-section {
        min-height: 860px;
        background: none;
        padding: 0;
    }

    .hero-caption h1 {
        font-size: 24px;
    }

    .hc-main-wrap {
        padding-top: 140px;
    }

    .hero-slide img {
        height: 100%;
        width: auto;
        max-width: inherit;
    }

    .wf-box {
        margin-bottom: 15px;
    } 

    .section.workpod-features {
        padding-bottom: 0;
    }

    .ms-search-btn button {
        width: 100%;
    }

    .tp-box {
        height: 220px;
    }

    .lp-slide {
        padding: 0 20px 20px 20px;
    }

    .landing-pod-slider .slick-next {
        right: -12px;
    }

    .landing-pod-slider .slick-prev {
        left: -12px;
    }

    .hero-caption {
        z-index: 9;
    }

    .autocomplete-dropdown-container .suggestion-item:first-child {
        border-radius: 4px 4px 0 0;
    } 

    .autocomplete-dropdown-container .suggestion-item:last-child {
        border-radius: 0 0 4px 4px;
    }

    .blog-img {
        height: 230px;
    }

    .blog-box {
        margin-bottom: 30px;
    }

    section.section.blog , section.section.hosts-outer {
        padding-bottom: 50px;
    }

    .partner-img {
        width: 100%;
        text-align: center;
    }

    .hosts-box {
        margin-bottom: 30px;
    }

    .f-info {
        margin-bottom: 30px;
    }

    .f-contact {
        margin-top: 30px;
    }

    .footer-inner {
        padding: 50px 0;
    }

    /* .listing-header {
        display: none;
    } */

    section.innerpage-filter {
        display: none;
    }

    .covid-info {
        margin-top: 20px;
    }

    .navigation ul li.host-btn {
        margin-left: 0;
        padding: 10px;
    }

    .custom-modal-login .modal-body-left {
        display: none;
    }

    .custom-modal-login .modal-body-right {
        width: 100%;
    } 

    .block-body {
        padding: 15px;
    }

    .about-section .block-col {
        display: block;
        float: left;
        padding: 15px 0;
        border-right: none;
        min-height: 100px;
    }

    .block-section .block-left {
        width: 100%;
    }

    .block-section .block-right {
        width: 100%;
    }

    .how-work-box {
        height: auto;
        padding: 30px 0;
    }

    .hw-content {
        padding-left: 0;
    }

    .hw-content h2 , .wp-feature .hw-content h2 {
        font-size: 22px;
        margin-bottom: 10px;
    }

    .mobile-column-reverce {
        flex-direction: column-reverse;
    }

    .hw-img {
        margin-bottom: 20px;
        height: auto;
    }

    .how-work-box.wp-feature {
        height: auto;
    }

    .hw-content.pl-none {
        padding-right: 0;
    }

    .contact-image {
        padding-top: 0;
        margin-bottom: 30px;
        padding-left: 0;
        padding-right: 0; 
    }

    .mobile-filter-toggle {
        display: inline-block;
        width: auto;
        font-size: 14px;
        color: #222;
        border: 1px solid #dfdfdf;
        padding: 6px 14px;
        border-radius: 40px;
        margin-bottom: 10px;
        margin-left: 15px;
    }

    section.innerpage-filter.filter-mobile {
        display: block;
    }

    .relative {
        position: relative;
    }

    .innerpage-filter-wrap {
        flex-direction: column;
    }

    .place , .place-info, .place-info.multi, .place-info, .filter-search {
        width: 100%;
        margin-right: 0;
        max-width: 100%;
        margin-bottom: 10px;
    }

    .more-filter-toggle {
        margin-bottom: 10px;
        display: none;
    }

    .map-view-btn {
        display: none;
    }

    .block-head {
        padding: 20px;
    }

    .block-head .title {
        font-size: 18px;
        line-height: 26px;
    }

    ul.list-inline.profile-host-info {
        flex-direction: column;
        align-items: flex-start;
    }

    .stm-outer h2 {
        padding-left: 15px;
    }

    .listing-main-wrap {
        flex-direction: column;
    }

    .listing-media {
        width: 100%;
    }

    .fpb-amenities {
        margin: 15px 0;
    }

    .listing-body {
        padding: 15px;
    }

    .table-filter {
        padding: 5px 15px 0 15px;
    }

    .table-filter .btn {
        font-size: 12px;
    }

    .podlisting-table {
        padding: 0 15px;
    }

    .wizard-step ul li {
        width: 100px;
        flex: 0 0 auto;
    } 

    .wizard-step ul li a:after {
        right: -60px;
    }

    .ck-book-box-wrap {
        margin-top: 0;
    }

    .wizard-step ul {
        justify-content: flex-start;
        overflow: auto;
        padding: 20px 0;
    }

    .wizard-step.hosting-wizard ul li a:after , .hosting-wizard.wizard-step ul li a.completed:after {
        right: -40px;
    } 

    .rq-box-footer {
        margin-top: 15px;
    }

    .rq-box-footer .btn {
        margin-bottom: 10px;
    }

    .rq-box {
        padding-bottom: 10px;
    }

    .rq-box-head h4 {
        font-weight: 600;
        margin-bottom: 15px;
    }

    .rdrMonths {
        flex-direction: column;
    }

    .fpb-image {
        height: 203px;
    }

    .mobile-checkout {
        width: 100%;
        position: fixed;
        bottom: 0;
        background: #fff;
        z-index: 9;
        left: 0;
        display: block;
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
    }

    .mobile-checkout.active {
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
    }

    .mc-head {
        width: 100%;
        background: #1da1f2;
        padding: 15px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
    }

    .mc-head p {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
    }

    .mc-head a {
        background: rgba(0,0,0,0.3);
        width: 30px;
        height: 30px;
        border-radius: 100%;
        color: #fff;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
    }

    .mc-content {
        width: 100%;
        height: 0;
        padding: 0 20px;
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
    }

    .mobile-checkout.active .mc-content {
        padding: 20px;
        height: auto;
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
    }

    .gallery-section img {
        width: 50%;
        height: auto;
    }

    .navigation ul li .dropdown {
        width: 100%;
    }

    .navigation ul li .dropdown a {
        width: auto;
    }

    .wizard-step {
        margin-bottom: 0;
    }

    .ck-login-outer {
        margin-top: 50px;
    }

    .ck-price > h3 {
        font-size: 20px;
    }

    .offer-outer h3 {
        font-size: 16px;
    }

    .ck-heading h2 {
        font-size: 24px;
    }

    .ck-trip h3 {
        font-size: 18px;
    }

    .ck-cancellation-policy h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .ck-cancellation-policy p , .ai-left h6 , .ai-left span , .edti-info a {
        font-size: 14px;
    }

    .checkout-outer {
        padding-top: 0;
    }

    .profile-inner h3 {
        font-size: 22px;
    }

    .host-page-heading h2 {
        font-size: 22px;
    }

    .host-page-heading {
        margin-bottom: 0;
    }

    .host-dashboard-outer .host-page-heading {
        margin-bottom: 20px;
    }

    .pre-dashboard-info {
        margin-bottom: 30px;
    }

    .host-dashboard-outer {
        padding-top: 30px;
    }

    .wnd-content {
        max-height: none;
    }

    .wizard-step ul li a p {
        text-align: center;
    }

    footer {
        border-top: 1px solid #dfdfdf;
    }

    .lp-details h4 {
        font-size: 13px;
    }

    .lp-details p {
        font-size: 11px;
    }

    .blog-sidebar {
        padding-bottom: 50px;
    }

    .tp-box img {
        width: auto;
        height: 100%;
    }

    .team-box {
        margin-bottom: 30px;
    }

    .workpod-team {
        padding-bottom: 20px;
    }
    
    .logo {
        width: 160px;
    }

    .menu-toggle {
        top: 21px;
    }

    .ms-input i {
        z-index: 0;
    }

    .innerpage-advance-filter {
        width: 100%;
        margin: 0;
        position: static;
    }

    .advance-filter-wrap {
        padding: 3px 15px 15px;
    }

    .more-filter-toggle {
        display: block;
    }

    .pr-slide img {
        height: auto;
    }

    .advance-filter-inner {
        margin-bottom: 6px;
    }
    
    a.btn.btn-primary.advance-filter-btn {
        padding: 6px 11px;
    }

    .block-icon svg {
        width: 50px;
        height: 30px;
    }

    h1.listing-title {
        font-size: 16px;
        line-height: 25px;
    }

    .listing-title {
        width: 75%;
    }

    .p-datatable .p-datatable-tbody > tr {
        border-bottom: 1px solid #dfdfdf!important;
    }

    .history-table-head {
        flex-direction: column;
        align-items: flex-start;
    }

    .h-accname {
        margin-bottom: 12px;
    }

    .h-date-filter {
        margin-left: 0;
    }

    .h-date-filter .p-inputtext:first-child {
        margin-left: 0 !important;
    }
    
    .h-date-filter .p-inputtext {
        width: 36%;
    }

    .export-excel {
        margin-left: 0;
    }

    .user-booked-listing .listing-item-footer {
        flex-direction: column;
        align-items: flex-end;
    }

    .manage-cancel-buttons {
        margin-top: 10px;
    }

    .apc-heading h2 {
        font-size: 22px;
        line-height: 30px;
    }

    .apc-inner-content h4 {
        font-size: 20px;
    }

    .apc-heading h3 {
        font-size: 20px;
        line-height: 30px;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {

    .menu-toggle {
        display: block;
        position: absolute;
        right: 30px;
        top: 29px;
        font-size: 26px;
        color: #333;
    }

    .navigation .nav-toggle {
        display: none;
    }

    .main-search {
        flex-direction: column;
    }

    .search-place , .ms-date {
        margin-right: 0;
        width: 100%;
        margin-bottom: 10px;
    }

    .wf-box {
        margin-bottom: 15px;
    }

    .section.workpod-features {
        padding-bottom: 0;
    }

    .tp-box {
        height: 216px;
    }

    .fpb-image {
        height: 200px;
    }

    .tp-box.comfortpod-box {
        height: 210px;
    }

    .hosts-box {
        margin-bottom: 40px;
    }

    .section.hosts-outer {
        padding-bottom: 30px;
    }

    .blog-img {
        height: 140px;
    }

    .blog-title {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .blog-content p {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .blog-category {
        margin-bottom: 5px;
        font-size: 14px;
    }

    .blog-author span {
        font-size: 11px;
        margin-right: 8px;
    }

    .blog-category i {
        font-size: 12px;
    }

    .section.our-partners {
        padding: 40px 0;
    }

    .footer-inner {
        padding: 40px 0;
    }

    .copyright-text p {
        font-size: 13px;
    }

    .hw-content {
        padding-left: 0;
    }

    .how-work-box {
        height: auto;
        padding: 30px 0;
    }

    .hw-content h2 {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 10px;
    }

    .hw-content p {
        font-size: 16px;
        margin-bottom: 0;
        line-height: 28px;
    }

    .hw-content.pl-none {
        padding-right: 0;
    }

    .wp-feature .hw-img img {
        max-height: 200px;
    }

    .hero-slide img {
        height: 100%;
        width: auto;
        max-width: inherit;
    }

    body.homepage .navigation ul li a {
        color: #222;
    }

    body.homepage .navigation ul li.host-btn a {
        color: #fff;
    }

    .homepage .menu-toggle {
        color: #fff;
    }

    .homepage.sticky-header .menu-toggle {
        color: #222;
    }

    .block-body {
        padding: 20px;
    }

    .stm-outer h2 {
        padding-left: 20px;
    }

    h1.listing-title {
        font-size: 20px;
        line-height:28px;
    }

    address, .pac-container {
        font-size: 13px;
        line-height: 22px;
    }

    .host-section .block-head .title {
        font-size: 18px;
        line-height: 26px;
    }

    .team-box img {
        width: 150px;
        height: 150px;
    }

    .sidebar-booking {
        margin-top: 30px;
    }

    .tablet-column-reverce {
        flex-direction: column-reverse;
    }

    .ck-login-outer {
        margin-top: 30px;
    }

    .ck-booking-confirm-outer {
        margin-top: 30px;
    }

    .wf-box h4 {
        font-size: 12px;
        font-weight: normal;
    }

}
